import _, { merge } from 'lodash';
import { EventState, EventAssetType, EventAssetState } from '../constants/constants';
import { USER_ID_PREFIX, EventUserLikeState, EventUserCommentState } from '../constants/constants';
import uuid from 'react-uuid'

import {
	FETCH_MY_STREAMS,
	FETCH_STREAM,
	FETCH_STREAM_ASSETS,
	FETCH_STREAM_ASSET,
	FETCH_STREAM_SETTINGS,
	FETCH_STREAM_ASSET_LIKES,
	FETCH_STREAM_ASSET_COMMENTS,
	FETCH_CUSTOM_STREAM_IMAGE,
	STREAM_ERROR,
	CLEAR_STREAM_ERROR,
	PROCESSING_ASSET
} from './streamActionTypes';

import { firestoreDB, storageRef, firebaseStorage } from '../firebase/firebase';
import { generateDynamicLink } from '../helpers/dynamicLinkHelper';
import { getVideoCover } from '../helpers';

const kFirebaseEventsTableName = 'events';
const kFirebaseAssetsTableName = 'assets'

export const fetchMyStreams = (streamState = EventState.Active) => async (dispatch, getState) => {

	const { authUser } = getState().auth

	if (authUser) {
		let query = firestoreDB.collection(kFirebaseEventsTableName).where(('members.' + USER_ID_PREFIX + authUser.uid), '==', true).where('state', '==', streamState)

		await query.get().then(snapshot => {

			if (snapshot.empty) {
				console.log('No matching documents.');
				return;
			}

			dispatch({ type: FETCH_MY_STREAMS, payload: snapshot })

			const streams = _.mapKeys(_.forEach(snapshot.docs, () => { }), 'id');

			_.forEach(streams, (value, key) => {
				if (value.data().primaryPhotoId !== null || value.data().primaryPhotoId !== undefined) {
					//dispatch(fetchStreamAsset(key, value.data().primaryPhotoId));
					dispatch(fetchStreamCustomImage(key, value.data().primaryPhotoId));
				}
			})

		}).catch(err => {
			console.log('Error getting documents', err);
			dispatch({ type: STREAM_ERROR, payload: err });
		});
	}

	
}

export const fetchStreamAssets = (streamId) => async (dispatch, getState) => {

	let moderatedApproved = 1

	let query = firestoreDB.collection(kFirebaseEventsTableName).doc(streamId).collection('assets').where('state', '<=', moderatedApproved)

	query.get().then(snapshot => {

		if (snapshot.empty) {
			console.log('No matching documents.');
			return;
		}

		dispatch({ type: FETCH_STREAM_ASSETS, payload: snapshot, streamId: streamId })

	}).catch(err => {
		console.log('Error getting documents', err);
		dispatch({ type: STREAM_ERROR, payload: err });
	});
}

export const fetchStreamAsset = (streamId, assetId) => async (dispatch, getState) => {

	if ((streamId === undefined) || (streamId === null)) {
		console.log(streamId);
		return;
	}

	if ((assetId === undefined) || (assetId === null)) {
		return;
	}

	let query = firestoreDB.collection(kFirebaseEventsTableName).doc(streamId).collection('assets').doc(assetId)

	query.get().then(snapshot => {

		if (snapshot.empty) {
			console.log('No matching documents.');
			return;
		}

		if (snapshot.data() !== undefined) {
			dispatch({ type: FETCH_STREAM_ASSET, payload: snapshot, streamId: streamId, assetId: assetId })
		}

	}).catch(err => {
		console.log('Error getting documents', err);
		dispatch({ type: STREAM_ERROR, payload: err });
	});
}

const fetchStreamCustomImage = (streamId, assetId) => async (dispatch, getState) => {

	if ((streamId === undefined) || (streamId === null)) {
		console.log(streamId);
		return;
	}

	if ((assetId === undefined) || (assetId === null)) {
		return;
	}

	let query = firestoreDB.collection(kFirebaseEventsTableName).doc(streamId).collection('assets').doc(assetId)

	query.get().then(snapshot => {

		if (snapshot.empty) {
			console.log('No matching documents.');
			return;
		}

		if (snapshot.data() !== undefined) {
			dispatch({ type: FETCH_CUSTOM_STREAM_IMAGE, payload: snapshot, streamId: streamId, assetId: assetId })
		}

	}).catch(err => {
		console.log('Error getting documents', err);
		dispatch({ type: STREAM_ERROR, payload: err });
	});
}

export const fetchStream = (streamId) => async (dispatch, getState) => {

	let query = firestoreDB.collection(kFirebaseEventsTableName).doc(streamId)

	query.get().then(snapshot => {

		if (snapshot.empty) {
			console.log('No matching documents.');
			return;
		}

		dispatch({ type: FETCH_STREAM, payload: snapshot, streamId: streamId })

	}).catch(err => {
		console.log('Error getting documents', err);
		dispatch({ type: STREAM_ERROR, payload: err });
	});
}

export const fetchStreamSettings = (streamId, userId) => async (dispatch, getState) => {

	let query = firestoreDB.collection(kFirebaseEventsTableName).doc(streamId).collection('settings').doc(USER_ID_PREFIX + userId);

	query.get().then(snapshot => {

		if (snapshot.empty) {
			console.log('No matching Settings documents.');
			return;
		}

		dispatch({ type: FETCH_STREAM_SETTINGS, payload: snapshot, streamId: streamId })

	}).catch(err => {
		console.log('Error getting documents', err);
		dispatch({ type: STREAM_ERROR, payload: err });
	});
}

export const fetchStreamAssetLikesAndComments = (streamId, assetId) => async (dispatch, getState) => {

	dispatch(fetchStreamAssetLikes(streamId, assetId));

	dispatch(fetchStreamAssetComments(streamId, assetId));
}

export const fetchStreamAssetLikes = (streamId, assetId) => async (dispatch, getState) => {

	let query = firestoreDB.collection(kFirebaseEventsTableName).doc(streamId).collection('assets').doc(assetId).collection('likes')

	query.get().then(snapshot => {

		if (snapshot.empty) {
			//console.log('No matching Likes documents.');
			return;
		}

		dispatch({ type: FETCH_STREAM_ASSET_LIKES, payload: snapshot, streamId: streamId, assetId: assetId })

	}).catch(err => {
		console.log('Error getting documents', err);
		dispatch({ type: STREAM_ERROR, payload: err });
	});
}

export const fetchStreamAssetComments = (streamId, assetId) => async (dispatch, getState) => {

	let query = firestoreDB.collection(kFirebaseEventsTableName).doc(streamId).collection('assets').doc(assetId).collection('comments')

	query.get().then(snapshot => {

		if (snapshot.empty) {
			//console.log('No matching Comments documents.');
			return;
		}

		dispatch({ type: FETCH_STREAM_ASSET_COMMENTS, payload: snapshot, streamId: streamId, assetId: assetId })

	}).catch(err => {
		console.log('Error getting documents', err);
		dispatch({ type: STREAM_ERROR, payload: err });
	});
}

export const updateStream = (streamId, snapshot) => (dispatch, getState) => {
	dispatch({ type: FETCH_STREAM, payload: snapshot, streamId: streamId })
}

export const updateStreamAssets = (streamId, snapshot) => (dispatch, getState) => {

	dispatch({ type: FETCH_STREAM_ASSETS, payload: snapshot, streamId: streamId })
}

export const updateStreamSettings = (streamId, snapshot) => (dispatch, getState) => {

	dispatch({ type: FETCH_STREAM_SETTINGS, payload: snapshot, streamId: streamId })
}

export const updateMyStreams = (snapshot) => (dispatch, getState) => {

	dispatch({ type: FETCH_MY_STREAMS, payload: snapshot })
}


const processLike = (assetId, streamId, state) => async (dispatch, getState) => {
	const { authUser } = getState().auth

	let date = new Date().getTime()
	let userID = USER_ID_PREFIX + authUser.uid
	firestoreDB.collection(kFirebaseEventsTableName).doc(streamId).collection('assets').doc(assetId).collection('likes').doc(userID).set({
		state: state,
		createdBy: userID,
		createdAt: date,
		updatedAt: date
	}).then(ref => {
		//dispatch(fetchStreamAssetLikes(streamId, assetId)) 
	}).catch(err => {
		dispatch({ type: STREAM_ERROR, payload: err });
	});
}

export const likeAsset = (assetId, streamId) => async (dispatch, getState) => {
	// const { authUser } = getState().auth
	// const db = firebase.firestore();

	// let date = new Date().getTime()
	// let userID = USER_ID_PREFIX + authUser.uid
	// let query = db.collection('events').doc(streamId).collection('assets').doc(assetId).collection('likes').doc(userID).set({
	//   state: EventUserLikeState.Liked,
	//   createdBy: userID,
	//   createdAt: date,
	//   updatedAt:date
	// }).then( ref => {
	//     //dispatch(fetchStreamAssetLikes(streamId, assetId)) 
	// })
	dispatch(processLike(assetId, streamId, EventUserLikeState.Liked))

}

export const unlikeAsset = (assetId, streamId) => async (dispatch, getState) => {
	// const { authUser } = getState().auth
	// const db = firebase.firestore();

	// let date = new Date().getTime()
	// let userID = USER_ID_PREFIX + authUser.uid
	// let query = db.collection('events').doc(streamId).collection('assets').doc(assetId).collection('likes').doc(userID).set({
	//   state: EventUserLikeState.Unliked,
	//   createdBy: userID,
	//   updatedAt:date,
	//   createdAt: date,
	// }).then( ref => {
	//   //dispatch(fetchStreamAssetLikes(streamId, assetId))
	// })
	dispatch(processLike(assetId, streamId, EventUserLikeState.Unliked))

}

export const clearStreamError = () => async (dispatch, getState) => {
	dispatch({ type: CLEAR_STREAM_ERROR, payload: null })
}

export const addAssetComment = (assetId, streamId, comment) => async (dispatch, getState) => {
	const { authUser } = getState().auth

	const date = new Date().getTime()
	const userID = USER_ID_PREFIX + authUser.uid
	firestoreDB.collection(kFirebaseEventsTableName).doc(streamId).collection('assets').doc(assetId).collection('comments').doc(uuid()).set({
		comment: comment,
		state: EventUserCommentState.Active,
		createdBy: userID,
		updatedAt: date,
		createdAt: date,
	}).then(ref => {
		//dispatch(fetchStreamAssetComments(streamId, assetId))
	}).catch(err => {
		dispatch({ type: STREAM_ERROR, payload: err });
	});
}

export const uploadFile = (file, eventId, event, assetId) => async (dispatch, getState) => {
	const { authUser } = getState().auth
	const userID = USER_ID_PREFIX + authUser.uid

	const date = new Date().getTime()
	// Create file metadata including the content type
	const metadata = {
		contentType: file.type,
	};

	var isVideo = false
	var assetType = EventAssetType.ImageAssetType
	if (file.type === 'video/mp4') {
		assetType = EventAssetType.VideoAssetType
		isVideo = true
	}

	var aId = assetId
	if (!aId) {
		aId = uuid();
	}
	

	if (!event || !eventId) {
		dispatch({ type: STREAM_ERROR, payload: {error:'event is null'} });
		return
	} 

	dispatch({type: PROCESSING_ASSET, payload: true})

	const uploadTask = storageRef.child(`${kFirebaseEventsTableName}/${eventId}/${aId}`).put(file, metadata);
	uploadTask.on('state_changed', 
		(snapshot) => {
			// Observe state change events such as progress, pause, and resume
			// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
			// var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
			// console.log('Upload is ' + progress + '% done');
			// switch (snapshot.state) {
			// case firebaseStorage.TaskState.PAUSED: // or 'paused'
			// 	console.log('Upload is paused');
			// 	break;
			// case firebaseStorage.TaskState.RUNNING: // or 'running'
			// 	console.log('Upload is running');
			// 	break;
			// }
		}, 
		(error) => {
			// Handle unsuccessful uploads
			dispatch({ type: STREAM_ERROR, payload: error });
		}, 
		() => {
			// Handle successful uploads on complete
			// For instance, get the download URL: https://firebasestorage.googleapis.com/...
			uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
				console.log('File available at', downloadURL);

				//Create an asset
				const asset = {
					assetId: aId,
					flagCount:0,
					type: assetType,
					url: downloadURL,
					//todo: check is admin
					state: ( !event.requireModeration ) ? EventAssetState.Approved : EventAssetState.NeedApproval,
					isApproved: event.requireModeration === false,
					creatorId: userID,
					updatedAt: date,
					createdAt: date,
				}

				updateAsset(asset, eventId)
				
				generateDynamicLink(eventId, aId, (shortLink) => {
					if (shortLink !== null) {
						const updatedAsset = {...asset, shareLink: shortLink}
						updateAsset(updatedAsset, eventId)

						if (isVideo) {
							getVideoCover(file, 5.0).then( thumb => {
								//console.log('thumbnail = ', thumb)
								storageRef.child(`${kFirebaseEventsTableName}/${eventId}/thumb_${aId}`)
								.put(thumb, {contentType: thumb.type}).then(snapshot => {
									snapshot.ref.getDownloadURL().then(thumbURl => {
										const thumbAsset = {...updatedAsset, thumbnailUrl: thumbURl}
										updateAsset(thumbAsset, eventId)
	
										dispatch({type: PROCESSING_ASSET, payload: false})
									})
								})
		
							})
						} else {
							dispatch({type: PROCESSING_ASSET, payload: false})
						}
						
					} else {
						if (isVideo) {
							getVideoCover(file, 5.0).then( thumb => {
								//console.log('thumbnail = ', thumb)
								storageRef.child(`${kFirebaseEventsTableName}/${eventId}/thumb_${aId}`)
								.put(thumb, {contentType: thumb.type}).then(snapshot => {
									snapshot.ref.getDownloadURL().then(thumbURl => {
										const thumbAsset = {...asset, thumbnailUrl: thumbURl}
										updateAsset(thumbAsset, eventId)
	
										dispatch({type: PROCESSING_ASSET, payload: false})
									})
								})
		
							})
						} else {
							dispatch({type: PROCESSING_ASSET, payload: false})
						}
					}
					
				})	

			});
		}
	);

}

function updateAsset(asset, eventId) {
	firestoreDB.collection(kFirebaseEventsTableName)
							.doc(eventId)
							.collection(kFirebaseAssetsTableName)
							.doc(asset.assetId)
							.set(asset, {merge:true}).then(()=>{
								fetchStreamAssets(eventId)
						});
}