import { PermissionResult, EventUserRole, maxUpload } from "../constants/constants"
import accountManagerInstance from "./AccountManager"
import { filter } from "lodash"


class PermissionManager {
	
	constructor() {
	// 	if (instance) {
	// 		throw new Error("New instance cannot be created!!");
	// 	  }

	// 	instance = this
	}

	canUploadAsset(event, userSettings, userId, callback) {

		console.log("event = ", event)
		console.log("userSettings = ", userSettings)

		accountManagerInstance.getCurrentUser()

		if (!event) {
			callback && callback(PermissionResult.GeneralDenied, "This stream is non-existent", 0)
			return
		}

		if (event.locked === true) {
			callback && callback(PermissionResult.GeneralDenied, "This stream is currently locked out by the admin", 0)
			return
		}

		if (userSettings) {
			if (userSettings.role === EventUserRole.Viewer) {
				callback && callback(PermissionResult.GeneralDenied, "You do not currently have permissions to perform this action.", 0)
				return
			}
		}

		if (event.purchaseId !== null){
            callback && callback(PermissionResult.Allowed, "", 100)
            return
        }

		if (accountManagerInstance.hasActiveSubscription() ) {
			callback && callback(PermissionResult.Allowed, "", 100)
			return
		}

		const userAssets = filter(event.assets, asset => asset.creatorId === userId)
		const result = this.hasReachedMaxUploads(event, event.assets, userId)
		const maxUploadReason = "You have exceeded the upload limit to this stream without a subscription to Loop. Please subscribe in the app now to unlock unlimited photo and video uploads to all of your streams."

		if (result !== PermissionResult.Allowed) {
			callback && callback(PermissionResult.GeneralDenied, maxUploadReason, 0)
		}

		var remaining = 0
		if (maxUpload - userAssets <= 0 ){
			callback && callback(PermissionResult.GeneralDenied, maxUploadReason, 0)
        } else {
            remaining = maxUpload - userAssets.length
        }

		callback && callback(PermissionResult.Allowed, "", remaining)
	}

	hasReachedMaxUploads(event, userAssets) {
		if (event && event.assets) {
			
			if (accountManagerInstance.hasActiveSubscription === false && userAssets.length >= maxUpload) {
				return PermissionResult.PaymentRequired
			}
		}
		return PermissionResult.Allowed
	}
}

const permissionManagerInstance = Object.freeze(new PermissionManager())

export default permissionManagerInstance
