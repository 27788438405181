import React from 'react';
import { connect } from 'react-redux';
import { fetchStream, fetchStreamAssets, fetchStreamAssetLikesAndComments } from '../actions/streamActions';
import StreamAssetDetail from '../components/StreamAssetDetail';
import _ from 'lodash';
import { toArrayWithKey } from '../helpers';
import { doesUserLikeAsset } from '../helpers/streams';
import { fetchUser } from '../actions/userActions';
import { renderLoader } from '../helpers/renderHelper';

class StreamAssetDetailContainer extends React.Component {

	state = { currentAssetIndex: 0, posterFetched: false }

	componentDidMount() {
		this.updateCurrentAssetIndex(this.props.assetId);
	}

	componentDidUpdate() {

		if (!this.state.posterFetched) {

			const { assets } = this.props;
			const asset = assets[this.state.currentAssetIndex];

			// console.log('assets = ', assets)
			// console.log('asset = ', asset)
			// console.log('currentAssetIndex = ', this.state.currentAssetIndex)

			if (asset) {
				const creatorID = asset.creatorId.substring(8, asset.creatorId.length) // Remove userId prefix
				this.props.fetchUser(creatorID)
				this.setState({ ...this.state, posterFetched: true });
			}
		}
	}

	onRightArrowClicked = () => {
		var index = this.state.currentAssetIndex;

		if (index === this.props.assets.length - 1) {
			return;
		}

		index++
		this.setState({ currentAssetIndex: index, posterFetched: false });
	}

	onLeftArrowClicked = () => {
		var index = this.state.currentAssetIndex;

		if (index === 0) {
			return;
		}

		index--
		this.setState({ currentAssetIndex: index, posterFetched: false });
	}

	updateCurrentAssetIndex = (assetId) => {

		const index = _.findIndex(this.props.assets, ['id', assetId])
		this.setState({ ...this.state, currentAssetIndex: index });
	}

	render() {

		const { stream, streamId, assetId, assets, onAssetLikeButtonClicked, onDismiss, authUser, user } = this.props;

		if ((stream !== undefined) && !_.isEmpty(stream.assets) && !_.isEmpty(assets)) {

			const asset = assets[this.state.currentAssetIndex];
			const isLiked = doesUserLikeAsset(asset, authUser);

			return (
				<StreamAssetDetail
					streamId={streamId}
					stream={stream}
					assetId={assetId}
					asset={asset}
					isAssetLiked={isLiked}
					isFirst={this.state.currentAssetIndex === 0}
					isLast={this.state.currentAssetIndex === (this.props.assets.length - 1)}
					onAssetLikeButtonClicked={onAssetLikeButtonClicked}
					onRightArrowClicked={this.onRightArrowClicked}
					onLeftArrowClicked={this.onLeftArrowClicked}
					onDismiss={onDismiss}
					postedBy={user}
					onAssetCommentButtonClicked={this.props.onAssetCommentButtonClicked}
				/>
			);
		} else {
			return renderLoader()
		}
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		stream: state.streams[ownProps.streamId], assets: toArrayWithKey(state.streams[ownProps.streamId].assets, 'id'), authUser: state.auth.authUser,
		user: state.user.user, auth: state.auth
	}
}

export default connect(mapStateToProps, { fetchStream, fetchStreamAssets, fetchStreamAssetLikesAndComments, fetchUser })(StreamAssetDetailContainer);