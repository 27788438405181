import React from 'react';
import ReactDOM from 'react-dom';

const Modal = (props) => {
	console.log(props)
  return ReactDOM.createPortal(
		<div onClick={props.onDismiss} className="ui dimmer modals visible active">
			<div onClick={(e) => e.stopPropagation()} 
				className="ui inverted modal visible active kithModalInner" >
				<div className="kithCellContent" >
					<div className="header">{props.title}</div>
					<br />
					<div className="content" style={{paddingBottom:'10px'}}>
						<p>{props.content}</p>
					</div>
					<button className="kithButton" onClick={props.onDismiss} style={{paddingTop:'10px', float:'right', marginBottom:'10px' }} >Ok</button>
				</div>
				
			</div>
		</div>,
    document.querySelector('#modal')
  );
}

// <div className="actions">
//   {props.actions}
// </div>

export default Modal;