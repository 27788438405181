//Implemented
export const FETCH_MY_STREAMS = 'FETCH_MY_STREAMS';
export const FETCH_STREAM = 'FETCH_STREAM';
export const FETCH_STREAM_ASSETS = 'FETCH_STREAM_ASSETS';
export const FETCH_STREAM_ASSET = 'FETCH_STREAM_ASSET';
export const FETCH_STREAM_SETTINGS = 'FETCH_STREAM_SETTINGS';
export const FETCH_STREAM_ASSET_LIKES = 'FETCH_STREAM_ASSET_LIKES';
export const FETCH_STREAM_ASSET_COMMENTS = 'FETCH_STREAM_ASSET_COMMENTS';
export const FETCH_CUSTOM_STREAM_IMAGE = 'FETCH_CUSTOM_STREAM_IMAGE';
export const PROCESSING_ASSET = 'PROCESSING_ASSET';

export const TEMPLATES = 'TEMPLATES';

// Not Implmemented
export const CREATE_STREAM = 'CREATE_STREAM';

export const DELETE_STREAM = 'DELETE_STREAM';
export const EDIT_STREAM = 'EDIT_STREAM';
export const LIKE_ASSET = 'LIKE_ASSET';
export const UNLIKE_ASSET = 'UNLIKE_ASSET';

export const STREAM_ERROR = "STREAM_ERROR"
export const CLEAR_STREAM_ERROR = "CLEAR_STREAM_ERROR"