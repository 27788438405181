import _ from 'lodash';
import { TEMPLATES,  } from '../actions/streamActionTypes';

export default (state = {}, action) => {

  switch (action.type) {

    case TEMPLATES:

        const templates = _.mapKeys(_.forEach(action.payload.docs, () => {}), 'id');

        // Flatten out the firebase object into just our asset model
        _.forEach(templates, (value, key) => {
          templates[key] = value.data();
        })

      return { ...state, ...templates};

    default:
      return state;
  }
}