export const DEFAULT_TEMPLATE = 'https://firebasestorage.googleapis.com/v0/b/faredgestudio-photos.appspot.com/o/templates%2Fstream-placeholder-image-6.jpg?alt=media&token=ae4aa6c2-2676-4a00-9528-d75ae9e34f6b';
export const USER_ID_PREFIX = 'AA65D930';

export const Values = {
  NumberMillisecondsPerDay: 86400000,
  CreateFirstStreamReminderDayCount: 5,
  PendingInviteDayCount: 7,
  InviteMembersReminderDayCount: 3,
  UploadAssetsReminderDayCount: 2,
  UserIdPrefix: "AA65D930",
  ServiceQueryLimit: 25
}

export const Defaults = {
  EnableNotifications: true,
  NotificationThreshold: 5
}

export const EventAssetState = {
  Approved: 0,
  ModeratedApproved: 1,
  NeedApproval: 2,
  Flagged: 3,
  ModeratedDenied: 4,
  Deleted: 100
}

export const EventUserCommentState = {

  Active: 0,
  Flagged: 1,
  Deleted: 100
}

export const EventUserLikeState = {
  Unknown: 0,
  Liked: 1,
  Unliked: 2
}

export const EventType = {
  EventCollection: 0,
  DeviceCollection: 1,
  GroupCollection: 2,
  LocationCollection: 3,
  All: 100
}

export const EventState = {
  Active: 0,
  Deleted: 1,
  Archived: 2,
  All: 100
}

export const EventNotificationType = {
  Unknown: "0",
  AssetAdded: "1",
  EventInvite: "2",
  AssetNeedsApproval: "3",
  AssetCommentAdded: "4",
  AssetLiked: "5",
  CreateStreamReminder: "6",
  InviteMembersReminder: "7",
  UploadAssetsReminder: "8",
  UpgradeRequested: "9"
}

export const EventAssetType = {
  UnknownAssetType: 0,
  ImageAssetType: 1,
  VideoAssetType: 2
}

export const EventUserRole = {
  Unknown: 0,
  Viewer: 1,
  Member: 2,
  Admin: 3,
  Removed: 4
}

export const EventUserType = {
  Anonymous: 0,
  Registered: 1,
  Monthly: 2,
  Cancelled: 3,
  Expired: 4,
  Complimentary: 5
}

export const JobType = {
  Archive: 0,
  Print: 1,
  DeleteAccount: 2,
  DeleteEvent: 3,
  RemoveMember: 4,
  UpgradeRequest: 5
}

export const JobState = {
  Requested: 0,
  Received: 1,
  InProgress: 2,
  Failed: 3,
  Completed: 4
}

export const ServiceNotificationTypes = {
  WelcomeEmailNotificationType: 0,
  StreamEndingEmailNotificationType: 1,
  PendingInviteEmailNotificationType: 2,
  CreateFirstStreamReminderEmailNotificationType: 3,
  AccountDeletedEmailNotificationType: 4,
  UploadAssetReminderPushNotificationType: 5,
  InviteMembersReminderPushNotificationType: 6,
  UploadAssetReminderEmailNotificationType: 7,
  InviteMembersReminderEmailNotificationType: 8,
  UpgradeRequestedPushNotificationType: 9,
  CreateFirstStreamReminderPushNotificationType: 10
}

export const EmailId = {
  WelcomeEmailId: "f7aee912-5443-4b4a-833e-b3d497ae9bbd",
  StreamEndingEmailId: "d9b4b9ba-96da-4ed2-be00-67528dcb00ee",
  PendingInviteEmailId: "20229503-e0f1-48f6-8801-8cd3cbebe26d",
  CreateFirstStreamReminderEmailId: "09de0794-6036-42ea-8511-fbb2cebb81fc",
  AccountDeletedEmailId: "1d0be308-76dc-4037-bded-30c7c41b326b",
  UploadAssetsReminderEmailId: "4829ecd8-6440-44cc-a863-19376f849db6",
  InviteMembersReminderEmailId: "192e820e-6fcf-4cce-97b7-83c6df438be8",
  StreamInviteEmailId: "c1b7b8be-7309-4a5e-afa2-48d0bb2c8d45",
  ArchiveDownloadEmailId: "f805c68d-e33f-44f8-ac91-80b8ddb14ec6",
  PurchaseConfirmationEmailId: "ca227faf-573a-4dc8-a309-34a128b4c44f"
}

export const PermissionResult = {
	Allowed: 0,
	AccountRequired: 1,
	GeneralDenied: 2,
	AnonymousLimitReached: 3,
	PaymentRequired: 4,
	StreamLimitReached: 5
}

export const kPurchaseIndividualLoopProductId = "com.faredgestudio.loop.individual"
export const kPurchaseMonthlySubscriptionProductId = "com.faredgestudio.loop.monthly"
export const kPurchaseAnnualSubscriptionProductId = "com.faredgestudio.loop.annual"

export const maxUpload = 10


export const appleRenewMessage = "Automatically renews. Cancel anytime. If you purchase Loop, payment will be charged to your Apple iTunes account and your account will be charged for renewal within 24 hours prior to the end of the current period.  Auto-renewal can be disabled by going to your settings in the Apple App Store after purchase."
export const androidRenewMessage = "Automatically renews. Cancel anytime. If you purchase Loop, payment will be charged to your Android Play Store account and your account will be charged for renewal within 24 hours prior to the end of the current period.  Auto-renewal can be disabled by going to your settings in the Android Play Store after purchase."