import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStreamAssetLikesAndComments, updateStream, updateStreamAssets, likeAsset, unlikeAsset, addAssetComment, updateStreamSettings, fetchStreamSettings, fetchStreamAssets } from '../actions/streamActions';
import NoContentController from '../controllers/NoContentController';
import firebase from 'firebase/compat/app'
import StreamDetail from '../components/StreamDetail';
import StreamAssetDetailContainer from './StreamAssetDetailContainer';
import AddCommentModal from '../components/modals/AddCommentModal';
import { doesUserLikeAsset } from '../helpers/streams';
import { STREAMDETAIL } from '../constants/routes';
import { useMatch } from 'react-router-dom';
import { renderLoader } from '../helpers/renderHelper';
import { USER_ID_PREFIX } from '../constants/constants';
import { generateDynamicLink } from '../helpers/dynamicLinkHelper';



const StreamDetailContainer = (props) => {

	const db = firebase.firestore();

	const [internalState, setInternalState] = useState({fullScreen: false, assetId: undefined, showAddComment: null, hasFetchedSettings: false})

	const matchpath = useMatch(STREAMDETAIL)
	const streamId = matchpath.params.id;

	const auth = useSelector(state => state?.auth) 
	const authUser = useSelector(state => state?.auth?.authUser) 
	const currentUser = useSelector(state => state?.auth?.currentUser) 

	const dispatch = useDispatch()

	const stream = useSelector(state => {
		// console.log('state = ', state)
		return state?.streams && state.streams[matchpath.params.id]
	}) 

	const hasFetchedComments = stream?.hasFetchedComments === true
	const hasFetchedAssets = stream?.hasFetchedAssets === true

	var settings
	if (authUser && stream?.settings) {
		settings = stream.settings[USER_ID_PREFIX + authUser?.uid]
	}

	// console.log('render settings = ', settings)
	// console.log('id = ', USER_ID_PREFIX + authUser?.uid)
	// console.log('stream = ', stream)
	// console.log('authUser = ', authUser)

	var streamListener
	var streamAssetsListener
	// var streamSettingsListener

	useEffect(()=> {
		registerFirebaseListeners();

		return () => {
			streamListener && streamListener();
			streamAssetsListener && streamAssetsListener();
			// streamSettingsListener && streamSettingsListener();
		}
	},[])

	useEffect(()=>{

		if (stream?.assets) {
			_.map(stream.assets, (assetValue, assetKey) => {
				dispatch(fetchStreamAssetLikesAndComments(matchpath.params.id, assetKey))
			});
		}

	},[hasFetchedComments, hasFetchedAssets])

	useEffect(()=>{

		if (authUser) {
			// console.log('streamId = ', streamId)
			// streamSettingsListener = db.collection('events').doc(streamId).collection('settings').doc(USER_ID_PREFIX + authUser.uid).onSnapshot(snapshot => {
			// 	console.log('settings snapshot = ', snapshot)
			// 	dispatch(updateStreamSettings(streamId, snapshot));
			// }, err => {
			// 	console.log(err);
			// })

			dispatch(fetchStreamSettings(streamId, authUser?.uid))

		}
	}, [authUser])

	function registerFirebaseListeners() {

		streamListener = db.collection('events').doc(streamId).onSnapshot(snapshot => {

			dispatch(updateStream(streamId, snapshot))
			dispatch(fetchStreamSettings(streamId, authUser?.uid))

			// dispatch(updateStream(streamId, snapshot)).then(()=>{
			// 	console.log('Here = ')
			// 	dispatch(fetchStreamAssets(streamId))
			// 	dispatch(fetchStreamSettings(streamId, USER_ID_PREFIX + authUser.uid))
			// })
		}, err => {
			console.log(err);
		});

		streamAssetsListener = db.collection('events').doc(streamId).collection('assets').onSnapshot(snapshot => {
			dispatch(updateStreamAssets(streamId, snapshot));
		}, err => {
			console.log(err);
		})
		
	}

	function onAssetClicked(assetId){
		setInternalState({
			fullScreen: true,
			assetId: assetId,
			showAddComment: null
		})

	}

	function onAssetLikeButtonClicked(assetId, asset){

		if (asset) {
			if (doesUserLikeAsset(asset, currentUser)) {
				unlikeAsset(assetId, streamId);
			} else {
				likeAsset(assetId, streamId);
			}
		}
		
	}

	function onAssetCommentButtonClicked(assetId, asset){
		//console.log('comment', assetId, asset)
		setInternalState({
			fullScreen: internalState.fullScreen,
			assetId: assetId,
			showAddComment: asset
		});
	}

	function onCommentSubmit(formValues){
		console.log('Comment = ', formValues.assetId, streamId, formValues.comment)

		dispatch(addAssetComment(formValues.assetId, streamId, formValues.comment))

		setInternalState({
			...internalState,
			showAddComment: null
		});
	}

	function dismissPortal() {
		//console.log('dismiss');
		setInternalState({
			fullScreen: false,
			assetId: internalState.assetId,
			showAddComment: null
		});
	}

	function dismissCommentModal() {
		//console.log('dismiss');
		setInternalState({
			fullScreen: internalState.fullScreen,
			assetId: internalState.assetId,
			showAddComment: null
		});
	}

	function renderPortal(){
		if (internalState.fullScreen) {
			return (
				<StreamAssetDetailContainer 
					streamId={streamId} 
					assetId={internalState.assetId} 
					onAssetLikeButtonClicked={ onAssetLikeButtonClicked } 
					onDismiss={ dismissPortal } 
					onAssetCommentButtonClicked={ onAssetCommentButtonClicked } />
			);
		}
	}

	function renderAddComment() {
		if (internalState.showAddComment !== null) {
			return <AddCommentModal onDismiss={ dismissCommentModal } onSubmit={ onCommentSubmit } asset={ internalState.showAddComment } assetId={internalState.assetId} />
		}
	}


	if (stream) {
		if (stream.assets) {
			return (
				<div className="kithContainer" >
					<StreamDetail 
						streamId={streamId} 
						stream={stream} 
						auth={auth} 
						settings={settings}
						onAssetClicked={ onAssetClicked } 
						onAssetLikeButtonClicked={ onAssetLikeButtonClicked } 
						onAssetCommentButtonClicked={  onAssetCommentButtonClicked} 
					/>
					{ renderPortal(stream) }
					{ renderAddComment() }
				</div>
			)
		} else {
			return renderLoader()
		}
	} else {
		return renderLoader()
	}

}

export default StreamDetailContainer


// class StreamDetailContainer extends React.Component {

// 	state = { fullScreen: false, assetId: undefined, showAddComment: null, hasFetchedSettings: false }

// 	componentDidMount() {
// 		console.log('this.props.match = ', this.props.match)

// 		if (this.props.match?.params) {
// 			this.props.fetchStream(this.props.match.params.id);
// 			this.props.fetchStreamAssets(this.props.match.params.id);
// 		}

// 		this.registerFirebaseListeners();

// 		this.hasFetchedLikes = false;
// 	}

// 	componentDidUpdate() {

// 		const { authUser } = this.props.auth;

// 		if ((this.streamSettingsListener === undefined) && (authUser !== null)) {
// 			this.registerFirebaseSettingsListeners();
// 		}

// 		if (this.props.stream != null && (this.props.stream.assets === undefined || this.props.stream.assets === null)) {
// 			this.props.fetchStreamAssets(this.props.match.params.id);
// 		}

// 		if (!this.state.hasFetchedSettings) {
// 			if (this.props.match?.params) {
// 				this.props.fetchStreamSettings(this.props.match.params.id, authUser.uid);
// 			}
			
// 			this.setState({
// 				...this.state,
// 				hasFetchedSettings: true
// 			});
// 		}


// 		// if(stream && stream.assets && !_.isEmpty(stream.assets) && !this.hasFetchedLikes){
// 		//   this.hasFetchedLikes = true
// 		//   _.map(this.props.stream.assets, (assetValue, assetKey) => {
// 		//     this.props.fetchStreamAssetLikesAndComments(this.props.match.params.id, assetKey);
// 		//   });
// 		// }

// 	}

// 	componentWillUnmount() {

// 		this.streamListener && this.streamListener();
// 		this.streamAssetsListener && this.streamAssetsListener();
// 		this.streamSettingsListener && this.streamSettingsListener();
// 	}

// 	registerFirebaseSettingsListeners = () => {

// 		const { authUser } = this.props.auth;

// 		const db = firebase.firestore();
// 		const streamId = this.props.match.params.id;

// 		this.streamSettingsListener = db.collection('events').doc(streamId).collection('settings').doc(USER_ID_PREFIX + authUser.uid).onSnapshot(snapshot => {
// 			this.props.updateStreamSettings(streamId, snapshot);
// 		}, err => {

// 		})
// 	}

// 	registerFirebaseListeners = () => {

// 		const db = firebase.firestore();
// 		const streamId = this.props.match.params.id;

// 		this.streamListener = db.collection('events').doc(streamId).onSnapshot(snapshot => {
// 			this.props.updateStream(streamId, snapshot);
// 		}, err => {
// 			console.log(err);
// 		});

// 		this.streamAssetsListener = db.collection('events').doc(streamId).collection('assets').onSnapshot(snapshot => {
// 			this.props.updateStreamAssets(streamId, snapshot);

// 			if (this.props.stream != null) {
// 				_.map(this.props.stream.assets, (assetValue, assetKey) => {
// 					this.props.fetchStreamAssetLikesAndComments(this.props.match.params.id, assetKey);
// 				});
// 			}
// 		}, err => {

// 		})
// 	}

// 	onAssetClicked = (assetId, asset) => {
// 		this.setState({
// 			fullScreen: true,
// 			assetId: assetId,
// 			showAddComment: null
// 		});
// 	}

// 	onAssetLikeButtonClicked = (assetId, asset) => {

// 		const streamId = this.props.match.params.id;
// 		let liked = doesUserLikeAsset(asset, this.props.auth.authUser)

// 		if (liked) {
// 			this.props.unlikeAsset(assetId, streamId);
// 		} else {
// 			this.props.likeAsset(assetId, streamId);
// 		}
// 	}

// 	onAssetCommentButtonClicked = (assetId, asset) => {
// 		//console.log('comment', assetId, asset)
// 		this.setState({
// 			fullScreen: this.state.fullScreen,
// 			assetId: assetId,
// 			showAddComment: asset
// 		});
// 	}

// 	onCommentSubmit = (formValues) => {
// 		console.log('Comment', formValues, this.props.match.params.id)

// 		this.props.addAssetComment(formValues.assetId, this.props.match.params.id, formValues.comment)

// 		this.setState({
// 			...this.state,
// 			showAddComment: null
// 		});
// 	}

// 	dismissPortal = () => {
// 		//console.log('dismiss');
// 		this.setState({
// 			fullScreen: false,
// 			assetId: this.state.assetId,
// 			showAddComment: null
// 		});
// 	}

// 	dismissCommentModal = () => {
// 		//console.log('dismiss');
// 		this.setState({
// 			fullScreen: this.state.fullScreen,
// 			assetId: this.state.assetId,
// 			showAddComment: null
// 		});
// 	}

// 	renderPortal = (stream) => {
// 		if (this.state.fullScreen) {
// 			return (
// 				<StreamAssetDetailContainer streamId={this.props.match.params.id} assetId={this.state.assetId} onAssetLikeButtonClicked={this.onAssetLikeButtonClicked} onDismiss={this.dismissPortal} onAssetCommentButtonClicked={this.onAssetCommentButtonClicked} />
// 			);
// 		}
// 	}

// 	renderAddComment = () => {
// 		if (this.state.showAddComment !== null) {
// 			return <AddCommentModal onDismiss={this.dismissCommentModal} onSubmit={this.onCommentSubmit} asset={this.state.showAddComment} assetId={this.state.assetId} />
// 		}
// 	}

// 	renderContent() {
// 		const { stream, auth } = this.props;

// 		if (stream) {
// 			if (stream.assets && !_.isEmpty(stream.assets)) {

// 				return (
// 					<div className="kithContainer" >
// 						<StreamDetail 
// 							stream={stream} 
// 							auth={auth} 
// 							settings={this.props.stream.settings}
// 							onAssetClicked={this.onAssetClicked} 
// 							onAssetLikeButtonClicked={this.onAssetLikeButtonClicked} 
// 							onAssetCommentButtonClicked={this.onAssetCommentButtonClicked} 
// 						/>
// 						{this.renderPortal(stream)}
// 						{this.renderAddComment()}
// 					</div>
// 				)
// 			} else {
// 				return (
// 					<NoContentController />
// 				)
// 			}
// 		} else {
// 			return <div />
// 		}
// 	}

// 	render() {

// 		const { stream } = this.props;

// 		// return (
// 		// 	<LoadingOverlay
// 		// 		active={stream == null}
// 		// 		spinner
// 		// 		text='Loading...'>

// 		// 		{this.renderContent()}
// 		// 	</LoadingOverlay>
// 		// )

// 		return (
// 			<div>
// 				{this.renderContent()}
// 			</div>
// 		)
// 	}
// }

// const mapStateToProps = (state, ownProps) => {
// 	var stream = null
// 	if (state.streams != null) {
// 		stream = state.streams[ownProps.match.params.id]
// 	}

// 	return {
// 		stream: stream,
// 		templates: state.templates,
// 		auth: state.auth
// 	};
// }

// export default connect(mapStateToProps, {
// 	fetchStreamAssetLikesAndComments,
// 	fetchStream,
// 	fetchStreamAssets,
// 	fetchStreamSettings,
// 	updateStream,
// 	updateStreamAssets,
// 	updateStreamSettings,
// 	likeAsset,
// 	unlikeAsset,
// 	addAssetComment
// })(StreamDetailContainer);