import _ from 'lodash';

export const toArrayWithKey = (obj, keyAs) => {
  return _.values(_.mapValues(obj, (value, key) => { 
    value[keyAs] = key; return value; 
  }));
}

export const getLikeCount = (asset) => {
	var like = 0
	if (asset?.likeCount !== undefined){
	    like = asset.likeCount
	}

	return like
}

export const getCommentCount = (asset) => {
	var commentCount = 0
	if (asset?.commentCount !== undefined){
	    commentCount = asset.commentCount
	}

	return commentCount
}

export  const MAX_FILE_SIZE = 51200 // 50MB
export function isFileSizeAllowable(selectedFile) {
	if (!selectedFile) {
		return false
	}

	const fileSizeKiloBytes = selectedFile.size / 1024

	if(fileSizeKiloBytes > MAX_FILE_SIZE){
		return false
	}

	return true
}

export function getVideoCover(file, seekTo = 0.0) {
    console.log("getting video cover for file: ", file);
    return new Promise((resolve, reject) => {
        // load the file to a video player
        const videoPlayer = document.createElement('video');
        videoPlayer.setAttribute('src', URL.createObjectURL(file));
        videoPlayer.load();
        videoPlayer.addEventListener('error', (ex) => {
            reject("error when loading video file", ex);
        });
        // load metadata of the video to get video duration and dimensions
        videoPlayer.addEventListener('loadedmetadata', () => {
            // seek to user defined timestamp (in seconds) if possible
            if (videoPlayer.duration < seekTo) {
                reject("video is too short.");
                return;
            }
            // delay seeking or else 'seeked' event won't fire on Safari
            setTimeout(() => {
              videoPlayer.currentTime = seekTo;
            }, 200);
            // extract video thumbnail once seeking is complete
            videoPlayer.addEventListener('seeked', () => {
                console.log('video is now paused at %ss.', seekTo);
                // define a canvas to have the same dimension as the video
                const canvas = document.createElement("canvas");
                // canvas.width = videoPlayer.videoWidth;
                // canvas.height = videoPlayer.videoHeight;

				canvas.width = 640;
                canvas.height = 480;

                // draw the video frame to canvas
                const ctx = canvas.getContext("2d");
                ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                // return the canvas image as a blob
                ctx.canvas.toBlob(
                    blob => {
                        resolve(blob);
                    },
                    "image/jpeg",
                    0.75 /* quality */
                );
            });
        });
    });
}

// export function getVideoCover(file, seekTo = 0.0){
// 	return new Promise((resolve) => {
// 	  const canvas = document.createElement("canvas");
// 	  const video = document.createElement("video");
  
// 	  // this is important
// 	  video.autoplay = true;
// 	  video.muted = true;
// 	  video.src = URL.createObjectURL(file);
  
// 	  video.onloadeddata = () => {
// 		let ctx = canvas.getContext("2d");
  
// 		canvas.width = video.videoWidth;
// 		canvas.height = video.videoHeight;
  
// 		ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
// 		video.pause();
// 		return resolve(canvas.toDataURL("image/png"));
// 	  };
// 	});
//   };