import React from 'react'
import NoContent from '../components/NoContent'

class NoContentController extends React.Component {
  state = {}

  render() {
    return (
      <NoContent />
    )
  }
}

export default NoContentController