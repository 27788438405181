import React from 'react';

const StreamGalleryCard = ({stream, streamId, imageUrl, fallbackUrl, onStreamClicked}) => {

  if(stream){

    return(
      <div className="card" style={{ height: '200px' }} onClick={() => onStreamClicked(streamId, stream)} >
        <img className="kithAssetCell"
            style={{ objectFit: 'cover', height: '200px' }}
            alt={ stream.title }
            src={ imageUrl }
            onError={ (e)=>{e.target.onerror = null; e.target.src=fallbackUrl} }
            />
        <div className="kithCellContent" 
          style={{ backgroundColor: 'rgba(30,30,30,0.5)', 
                  position: 'absolute', 
                  overflow: 'hidden', 
                  top: '82%', 
                  width: '100%', 
                  paddingTop:'10px', 
                  paddingBottom:'10px' }}>
          <div className="header" style={{ color: 'white' }}>{ stream.title }</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="card" >
        <div class="ui segment">
          <div class="ui active dimmer">
            <div class="ui text loader">Loading</div>
          </div>
          <p></p>
        </div>
      </div>
    )
  }
}

export default StreamGalleryCard;