import React from 'react';
import ReactDOM from 'react-dom';
import ChangePasswordForm from '../forms/ChangePasswordForm';

const ChangePasswordModal = ({ onDismiss, onSubmit }) => {
	return ReactDOM.createPortal(
		<div className="ui container">
			<div className="ui dimmer modals visible active kithModalOuter" onClick={onDismiss}  >
				<div onClick={(e) => e.stopPropagation()} className="ui inverted modal visible active kithModalInner" >
						<ChangePasswordForm onSubmit={ onSubmit } onDismiss={ onDismiss }/>
				</div>
			</div>
		</div>
		,
		document.querySelector('#modal')
	);	
}

export default ChangePasswordModal;