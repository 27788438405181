import React, { Fragment, useEffect } from 'react';
import {Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { fetchAuthUser, fetchKithUser, fetchUserPurchases } from '../actions/authActions';

import * as ROUTES from '../constants/routes';

import HomeContainer from '../containers/HomeContainer';
import AccountContainer from '../containers/AccountContainer'
import StreamListContainer from '../containers/StreamListContainer';
import StreamDetailContainer from '../containers/StreamDetailContainer';
import ContactContainer from '../containers/ContactContainer';
import StreamAssetDetailContainer from '../containers/StreamAssetDetailContainer';
import PrivacyContainer from '../containers/PrivacyContainer';
import TermsContainer from '../containers/TermsContainer';

import PageNotFound from '../components/PageNotFound'

import Navigation from './Navigation';
import Footer from './Footer'
import accountManagerInstance from '../managers/AccountManager';

const App = () => {

	const auth = useSelector(state => state.auth)
	const authUser = useSelector(state => state.auth.authUser)
	const currentUser = useSelector(state => state.auth.authUser)
	const isSignedIn = useSelector(state => state.auth.isSignedIn )
	const dispatch = useDispatch()

	useEffect(()=> {

		dispatch(fetchAuthUser())

		document.body.style.background = "black";  //hack? - Arthur

		if (authUser !== null && currentUser === undefined) {
			dispatch(fetchKithUser(authUser.uid))
		}

		if (currentUser) {
			accountManagerInstance.setCurrentUser(currentUser)
			accountManagerInstance.setAuth(auth)
			dispatch(fetchUserPurchases())
		}

	},[currentUser, authUser])


	return (
		<div className="appMain" style={{height:'100vh'}}>
			<Navigation />
			<div className="ui container" >
				<Routes >
					<Route path={ROUTES.HOME} element={ isSignedIn === true ?  <StreamListContainer />: <HomeContainer />} />
					<Route path={ROUTES.STREAMDETAIL} element={ <StreamDetailContainer />} />
					<Route path={ROUTES.STREAMS} element={ <StreamListContainer />} />

					<Route path={ROUTES.ACCOUNT} element={ <AccountContainer />} />
					<Route path={ROUTES.CONTACT} element={ <ContactContainer />} />
					<Route path={ROUTES.STREAMASSETDETAIL} element={ <StreamAssetDetailContainer /> } />
					<Route path={ROUTES.PRIVACY} element={ <PrivacyContainer /> } />
					<Route path={ROUTES.TERMS} element={ <TermsContainer />} />
					<Route path="*" element={ <PageNotFound />} />
					<Route path="/404" element={ <PageNotFound /> } />
					{/* <Navigate to="/404" /> */}
				</Routes>
			</div>
			<Footer />
		</div>
	)

}

export default App
