import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import './fonts/RobotoCondensed-Regular.ttf';
import './css/AppTheme.css'
import {createStore, applyMiddleware, compose } from 'redux';

import 'firebase/compat/auth'
import 'firebase/compat/firestore'

import App from './components/App';

import reducers from './reducers';
import { HashRouter } from 'react-router-dom';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<HashRouter>
			<Provider store={store} >
				<App />
			</Provider>
		</HashRouter>
	</React.StrictMode>
);

// ReactDOM.render(
//   <Provider store={store} >
//     <App />
//   </Provider>,
//   document.querySelector('#root')
// );


