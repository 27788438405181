import React, { useEffect } from 'react';
import ChangePasswordModal from './modals/ChangePasswordModal'
import EditAccountModal from './modals/EditAccountModal'

import photoPlaceholder from '../assets/photo_placeholder.svg';

const renderChangePassword = (showChangePassword, onDismiss, onSubmit) => {

  if (showChangePassword !== undefined) {
    if (showChangePassword) {
      return (
        <ChangePasswordModal onDismiss={onDismiss} onSubmit={onSubmit} />
      );
    }
  }

  return null;
}

const renderEditAccount = (editMode, onDismiss, onSubmit) => {

  if (editMode !== undefined) {
    if (editMode) {
      return (
        <EditAccountModal onDismiss={onDismiss} onSubmit={onSubmit} />
      );
    }
  }

  return null;
}

const Account = ({ auth,
	showChangePassword,
	onSignOutClicked,
	onEditAccountClicked,
	editMode,
	onEditAccountSubmit,
	onCancelClicked,
	onChangePasswordClicked,
	onChangePasswordSubmit,
	onDismiss,
	showOpenFileDlg }) => {

	var displayName = "";
	var email = "";
	var fullName = "";
	var avatarURL = photoPlaceholder;

	if (auth.authUser) {
		email = auth.authUser.email
	}

	if (auth.currentUser) {
		displayName = auth.currentUser.userName
		fullName = auth.currentUser.fullName

		if (auth.currentUser.avatarURL !== undefined && auth.currentUser.avatarURL !== null) {
			avatarURL = auth.currentUser.avatarURL
		}
	}

	// console.log(auth.currentUser)

	return (
		<div className='kithProfileContainer'>
		<div className="kithProfileGrid">
			<div className="ui grid container">
			<div className="three column row">
				<div className="ui right aligned column" >
				<div className="kithAutoOverFlow">Fullname</div>
				<br />
				<div className="kithAutoOverFlow">Display Name</div>
				<br />
				<div className="kithAutoOverFlow" >Email</div>
				</div>

				<div className="ui left aligned column" >
				<div className="kithAutoOverFlow">{fullName}</div>
				<br />
				<div className="kithAutoOverFlow">{displayName}</div>
				<br />
				<div className="kithAutoOverFlow">{email}</div>
				</div>

				<div className="ui right aligned column" >
				<div style={{ width:'12vmin', height:'12vmin', float:'right'}}>
					<img className="ui medium circular image"
					style={{width:'100%', height:'100%'}}
					src={avatarURL}
					alt=""
					onError={(e) => { e.target.onerror = null; e.target.src = photoPlaceholder }}
					onClick={showOpenFileDlg} />
				</div>
				</div>
			</div>
			<div className="ui divider" style={{marginTop:'-5px', marginBottom:'-5px'}}/>
			<div className="two column row">
				<div className="column" >
				<button className="ui kithButtonSecondary" style={{marginRight:'10px', marginBottom:'5px'}} onClick={onChangePasswordClicked} >Change Password</button>
				<button className="ui kithButtonSecondary"  style={{marginTop:'5px'}} onClick={onEditAccountClicked}>Edit Account</button>
				</div>
				<div className="right aligned column" >
				<button className="ui kithButtonSecondary" style={{marginTop:'5px'}} onClick={onSignOutClicked}>Sign Out</button>
				</div>
			</div>

			{renderChangePassword(showChangePassword, onDismiss, onChangePasswordSubmit)}
			{renderEditAccount(editMode, onDismiss, onEditAccountSubmit)}
			</div>
		</div>
		</div>
  	)
}

export default Account;