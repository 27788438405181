import React from 'react';
import emptyImage from '../assets/empty_images.png'

const EmptyStream = () => {

  return (
	<div className="ui centered grid" style={{marginTop:-144}}>
		<div className="row">
			<img src={emptyImage} alt="No Content Logo" style={{height:'90vh'}}/>
		</div>
		<div className="row">
			<p style={{ marginLeft:'40px', marginRight:'40px', marginTop:-90}}>Invite, capture, or upload to your photostream.</p>
		</div>
  </div>
    
  )
}

export default EmptyStream;