import React, { Fragment, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import StreamAssetCard from './StreamAssetCard';
import { getLikeCount, isFileSizeAllowable } from '../helpers';
import { doesUserLikeAsset } from '../helpers/streams';
import permissionManagerInstance from '../managers/PermissionManager'
import { EventAssetState, PermissionResult, USER_ID_PREFIX } from '../constants/constants';
import accountManagerInstance from '../managers/AccountManager';
import Modal from './modals/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../actions/streamActions';
import { renderLoader } from '../helpers/renderHelper';
import add from '../assets/add.svg'
import EmptyStream from './EmptyStream';

const StreamDetail = ({ streamId, stream, auth, onAssetClicked, onAssetLikeButtonClicked, onAssetCommentButtonClicked, settings }) => {

	// const userId = USER_ID_PREFIX + auth.authUser?.uid
	// const userSettings = settings && settings[userId]

	// console.log('StreamDetail settings = ', settings)

	const inputRef = useRef(null)
	const dispatch = useDispatch()

	const isProcessingAsset = useSelector(state => state?.streams.isProcessingAsset) 

	const [errorMessage, setErrorMessage] = useState('')

	const [fileAllowable, setFilesAllowable] = useState(0)

	const assetCards = _.map(_.filter(stream.assets, asset => asset.state === EventAssetState.Approved), (assetValue, assetKey) => {

		let isLiked = doesUserLikeAsset(assetValue, auth.authUser)
		let assetLikeCount = getLikeCount(assetValue)

		return <StreamAssetCard key={assetKey} asset={assetValue} auth={auth} isAssetLiked={isLiked} assetLikeCount={assetLikeCount} assetId={assetValue.id} onAssetClicked={onAssetClicked} onAssetLikeButtonClicked={onAssetLikeButtonClicked} onAssetCommentButtonClicked={onAssetCommentButtonClicked} />
	});

	useEffect(()=>{
		accountManagerInstance.setAuth(auth)
	},[auth])

	function uploadAsset(e) {
		e.preventDefault()

		permissionManagerInstance.canUploadAsset(stream, settings, USER_ID_PREFIX + auth?.authUser?.uid, (result, reason, count) => {
			console.log("result = ", result)
			console.log("reason = ", reason)
			console.log("count = ", count)

			if(result === PermissionResult.Allowed) {
				setFilesAllowable(count)
				if (count <= 0) {
					setErrorMessage(`You have exceeded the upload limit to this stream without a subscription to Loop. Please subscribe in the app now to unlock unlimited photo and video uploads to all of your streams`)
				} else {
					inputRef?.current?.click()
				}
				
			} else {
				setErrorMessage(reason)
			}
		})
	}

	function renderError() {
		if (!_.isEmpty(errorMessage)) {

			var params = {
				title: 'Error',
				content: errorMessage,
				onDismiss: ()=>{
					setErrorMessage('')
				}
			}

			return <Modal {...params} />
		}	
	}

	function renderFilePicker() {
		return <input type="file" accept="image/*,video/mp4" 
				multiple 
				style={{display: 'none'}}
				onChange={ e => pickFileHandler(e) } 
				ref={inputRef} />
	}

	async function pickFileHandler(ev) {
		ev.preventDefault();

		console.log('ev.target.files = ', ev.target.files);
		if (ev.target.files) {
			if(ev.target.files.length > fileAllowable) {
				setErrorMessage(`You can only pick up to ${fileAllowable} files`)
			} else {
				_.forEach(ev.target.files, file => {
					if(isFileSizeAllowable(file) === true) {
						dispatch(uploadFile(file, streamId, stream, undefined))
					} else {
						setErrorMessage(`Please select a file that is lesser than 50MB is size`)
					}
				})

				//console.log('uploading  ', ev.target.files);	
			}
			
		}

		if (inputRef?.current) {
			inputRef.current.value = '';
		}
	}

	return (
		<Fragment>
			<button className="kithUploadButton"  onClick={e => uploadAsset(e)}>
				<img className="kithUploadButton" src={add} alt="add button"/>
			</button>
			<div className="ui container" style={{ paddingTop: 30 }}>
				{ _.isEmpty(assetCards) ?
					<EmptyStream />
					:
					<div className="ui three stackable cards">
						{assetCards}
					</div>
				}
				
			</div>
			{ isProcessingAsset === true && renderLoader()}
			{ renderError() }
			{ renderFilePicker() }
		</Fragment>

	);
}

export default StreamDetail;
