import _ from 'lodash';
import {
	FETCH_STREAM,
	FETCH_MY_STREAMS,
	FETCH_STREAM_ASSETS,
	FETCH_STREAM_ASSET,
	FETCH_STREAM_SETTINGS,
	FETCH_STREAM_ASSET_LIKES,
	FETCH_STREAM_ASSET_COMMENTS,
	FETCH_CUSTOM_STREAM_IMAGE,
	LIKE_ASSET,
	STREAM_ERROR,
	CLEAR_STREAM_ERROR,
	UNLIKE_ASSET,
	PROCESSING_ASSET,
} from '../actions/streamActionTypes';

export default (state = null, action) => {

	switch (action.type) {

		case FETCH_STREAM:

			if (!_.isEmpty(state)) {

				return { ...state, [action.streamId]: action.payload.data() };
			} else {
				var oneStream = {};
				oneStream[action.streamId] = {...action.payload.data(), hasFetchedComments: false, hasFetchedAssets:false };
				return { ...state, ...oneStream };
			}

		case FETCH_MY_STREAMS:

			let sorted = _.sortBy(action.payload.docs, [function (o) { return o.data().createdAt; }])
			sorted = _.reverse(sorted)

			const streams = _.mapKeys(_.forEach(sorted, () => { }), 'id');

			// Flatten out the firebase object into just our stream model
			_.forEach(streams, (value, key) => {
				streams[key] = {...value.data(), eventId: key};
			})

			return { ...streams };

		case FETCH_STREAM_ASSETS:

			if (!_.isEmpty(state)) {

				let sorted = _.sortBy(action.payload.docs, [function (o) { return o.data().createdAt; }])
				sorted = _.reverse(sorted)

				const streamAssets = _.mapKeys(_.forEach(sorted, () => { }), 'id');

				// Flatten out the firebase object into just our asset model
				_.forEach(streamAssets, (value, key) => {
					streamAssets[key] = {...value.data(), id: key};
				})

				return { ...state, [action.streamId]: { ...state[action.streamId], assets: streamAssets, hasFetchedComments: false, hasFetchedAssets: true } };
			}

			return state;

		case FETCH_STREAM_ASSET:

			if (!_.isEmpty(state)) {

				const streamAssets = {};
				// console.log(action.payload.data());
				streamAssets[action.assetId] = action.payload.data();

				return { ...state, [action.streamId]: { ...state[action.streamId], assets: streamAssets, hasFetchedComments: false, hasFetchedAssets: true } };
			}

			return state;

		case FETCH_CUSTOM_STREAM_IMAGE:
			if (!_.isEmpty(state)) {
				return {
					...state, [action.streamId]: {
						...state[action.streamId],
						customUrl: action.payload.data().thumbnailUrl, //We append customUrl and customUrlFallback into the stream object
						customUrlFallback: action.payload.data().url
					}
				};
			}

			return state;

		case FETCH_STREAM_SETTINGS:

			if (action.payload.data()) {
				const settings = {};
				settings[action.payload.id] = action.payload.data();

				// console.log("settings = ", settings)
				return { ...state, [action.streamId]: { ...state[action.streamId], settings: settings } };
			} 
			
			return { ...state}

		case FETCH_STREAM_ASSET_LIKES:

			if (!_.isEmpty(state)) {

				const assetLikes = _.mapKeys(_.forEach(action.payload.docs, () => { }), 'id');

				// Flatten out the firebase object into just our asset model
				_.forEach(assetLikes, (value, key) => {
					assetLikes[key] = value.data();
				})

				return { ...state, [action.streamId]: { ...state[action.streamId], assets: { ...state[action.streamId].assets, [action.assetId]: { ...state[action.streamId].assets[action.assetId], likes: assetLikes } } } };
			}

			return state;

		case FETCH_STREAM_ASSET_COMMENTS:

			if (!_.isEmpty(state)) {

				let sorted = _.sortBy(action.payload.docs, [function (o) { return o.data().createdAt; }])
				sorted = _.reverse(sorted)

				const assetComments = _.mapKeys(_.forEach(sorted, () => { }), 'id');

				// Flatten out the firebase object into just our asset model
				_.forEach(assetComments, (value, key) => {
					assetComments[key] = value.data();
				})

				return { ...state, [action.streamId]: { ...state[action.streamId], hasFetchedComments: true ,assets: { ...state[action.streamId].assets, [action.assetId]: { ...state[action.streamId].assets[action.assetId], comments: assetComments} } } };
			}

			return state;

		case LIKE_ASSET:
			return state;

		case UNLIKE_ASSET:
			return state;
		case STREAM_ERROR:
			return { ...state, streamError: action.payload };
		case CLEAR_STREAM_ERROR:
			return { ...state, streamError: undefined };

		case PROCESSING_ASSET:
			return {...state, isProcessingAsset: action.payload}	
		default:
			return state;
	}
}