import React from 'react';
import { Field, reduxForm } from 'redux-form';

class ChangePasswordForm extends React.Component {

	renderError({error, touched}) {
		if (touched && error) {
		  return (
		    <div className="ui error message">
		      <div className="header">{error}</div>
		    </div>
		  )
		}

		return null
	}

	renderInput = ({input, type, label, meta}) => {
		const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
		return (
		  <div className={className} >
		    <input {...input} autoComplete="off" type={type} placeholder={label} />
		    <div>{this.renderError(meta)}</div>
		  </div>
		);
	}

	onSubmit = (formValues) => {
		this.props.onSubmit(formValues);
	}

	render() {
		return (
	      <form className="ui inverted form error" onSubmit={this.props.handleSubmit(this.onSubmit)} style={{ padding: '15px 20px 15px 20px' }}>
					<div className="ui medium kithModalTitle">Change Password</div>
	        <Field name="oldpassword" component={this.renderInput} label="Old Password" type="password" />
					<br />
	        <Field name="newpassword" component={this.renderInput} label="New Password" type="password" />
					<Field name="confirmnewpassword" component={this.renderInput} label="Confirm New Password" type="password" />

					<button className="kithButton kithModalPositive" >Submit</button>
					<button className="ui button kithButtonSecondary" onClick={ this.props.onDismiss }>Cancel</button>
	      </form>
	    );
	}	
}


const validate = (formValues) => {

  const errors = {};

  if (!formValues.oldpassword) {
    errors.password = 'You must enter your old password';
  }

  if (!formValues.newpassword) {
    errors.password = 'You must enter a new password';
  }

  return errors;
}	

export default reduxForm({
  form: 'changePasswordForm',
  validate: validate
})(ChangePasswordForm);