import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import looplogo from '../assets/Loop-Photostream-web-logo.png';

import Modal from './modals/Modal';

import { signInWithEmailAndPassword, clearAuthError, forgotPassword, clearPasswordSentSuccess } from '../actions/authActions';

import * as ROUTES from '../constants/routes';

// import PropTypes from "prop-types";

import userPlaceholder from '../assets/user_placeholder.svg';
import navicon from '../assets/navicon.svg';
import history from '../history';

class Navigation extends React.Component {

	constructor() {
		super()

		this.focusRef = React.createRef()

		this.state = {
			home: 'item active',
			streams: 'item',
			apps: 'item',
			contact: 'item',
			showMenu: false,
			showLogin: false,
			showLoading: false,
			email: null,
			password: null
		}
	}

	renderAccountItem() {

		var avatarURL = userPlaceholder;
		if (this.props.auth.currentUser && this.props.auth.currentUser.avatarURL !== undefined && this.props.auth.currentUser.avatarURL !== null) {
			avatarURL = this.props.auth.currentUser.avatarURL
		}

		if (this.props.isSignedIn) {
			return <Link to={ROUTES.ACCOUNT} className="item" >
				<img
					src={avatarURL}
					alt=""
					style={{ borderRadius: '50%', objectFit: 'cover', width: '30px', height: '30px', position: 'relative', marginBottom: '10px' }}
					onError={(e) => { e.target.onerror = null; e.target.src = userPlaceholder }} />
			</Link>
		} else {
			return <a className="item kithLinkSelected"  href="#">SIGN IN</a>
		}
	}

	renderSignOut() {
		if (this.props.isSignedIn) {
			return (
				<div className="item">
					<button className="ui inverted kithButton">Sign Out</button>
				</div>
			);
		}
	}

	renderAuthenticatedItems() {
		if (this.props.isSignedIn) {
			return (
				<Link className="item kithLinkSelected" to={ROUTES.STREAMS}>STREAMS</Link>
			)
		}
	}


	// componentWillUnmount() {
	// 	this.unlisten();
	// }

	// componentDidMount() {
	// 	this.unlisten = history.listen((location, action) => {
	// 		this.setSelected(history.location.pathname)
	// 	});
	// 	this.setSelected(history.location.pathname)
	// }

	// setSelected = (key) => {
	// 	var defaultState = {
	// 		...this.state,
	// 		home: 'item',
	// 		streams: 'item',
	// 		apps: 'item',
	// 		firetv: 'item',
	// 		contact: 'item',
	// 		showMenu: this.state.showMenu
	// 	}

	// 	if (key === ROUTES.MOBILEAPPS) {
	// 		defaultState = { ...defaultState, apps: 'item active' }
	// 	} else if (key === ROUTES.HOME) {
	// 		defaultState = { ...defaultState, home: 'item active' }
	// 	} else if (key === ROUTES.FIRETV) {
	// 		defaultState = { ...defaultState, firetv: 'item active' }
	// 	} else if (key === ROUTES.CONTACT) {
	// 		defaultState = { ...defaultState, contact: 'item active' }
	// 	} else if (key === ROUTES.STREAMS) {
	// 		defaultState = { ...defaultState, streams: 'item active' }
	// 	}

	// 	this.setState(defaultState)
	// }

	onDismissError = () => {
		this.props.clearAuthError();

		this.setState({
			...this.state,
			error: null,
			showLoading: false,
		});
	}

	onDismissSuccess = () => {
		this.props.clearPasswordSentSuccess();

		this.setState({
			...this.state,
			showLoading: false,
		});
	}

	renderError = () => {
		const { authError } = this.props.auth;

		if (authError !== null) {

			if (this.state.showLoading !== false) {
				this.setState({
					...this.state,
					showLoading: false,
					showLogin: false
				});
			}

			var params = {
				title: 'Error',
				content: authError.message,
				onDismiss: this.onDismissError
			}

			return (
				<Modal {...params} />
			);
		}
	}

	renderForgotPasswordSuccess = () => {

		if (this.props.forgotPasswordSent) {
			if (this.state.showLoading !== false) {
				this.setState({
					...this.state,
					showLoading: false,
					showLogin: false
				});
			}

			var params = {
				title: 'Success',
				content: 'Please check your email to reset your password',
				onDismiss: this.onDismissSuccess
			}

			return (
				<Modal {...params} />
			);
		}

	}

	showVericalMenu() {
		if (this.state.showMenu) {
			return (
				<div className="ui inverted vertical menu">
					<Link to={ROUTES.HOME} className={this.state.home} style={{ textDecoration: 'none', color:'white' }}>HOME</Link>
					{this.renderAuthenticatedItems()}
					<Link to={ROUTES.CONTACT} className={this.state.contact} style={{ textDecoration: 'none', color:'white' }}>CONTACT</Link>
					{this.renderAccountItem()}
				</div>
			)
		} else {
			return <div />
		}
	}

	render() {

		return (
			<div className="kithMenu">

				<div className="ui inverted text menu" style={{background:'transparent'}}>

					<a href="https://loopphotostream.com/">
						<img className="lazy" src={ looplogo } width="223" height="88" alt="Loop PHOTOSTREAM" id="logo" data-height-percentage="80" data-eio="l" /> 
					</a>

					<div className="right menu">
						<a className="link item kithLink"  href="https://loopphotostream.com/features/">FEATURES</a>
						<a className="link item kithLink"  href="https://loopphotostream.com/how-it-works/">HOW IT WORKS</a>
						<a className="link item kithLink" href="https://loopphotostream.com/why-loop/">WHY LOOP</a>
						<a className="link item kithLink" href="https://loopphotostream.com/pricing/">PRICING</a>
						<a className="link item kithLink" href="https://loopphotostream.com/faq/">FAQ</a>
						<a className="link item kithLink" href="https://loopphotostream.com/about/">ABOUT</a>
						<a className="link item kithLink" href="https://loopphotostream.com/download-now/">DOWNLOAD NOW</a>
						{this.renderAuthenticatedItems()}
						{this.renderAccountItem()}
					</div>
				</div>
				
				{this.renderError()}
				{this.renderForgotPasswordSuccess()}
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return { isSignedIn: state.auth.isSignedIn, auth: state.auth, forgotPasswordSent: state.auth.forgotPasswordSent }
}

export default connect(mapStateToProps, { signInWithEmailAndPassword, clearAuthError, forgotPassword, clearPasswordSentSuccess })(Navigation);