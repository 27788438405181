import { DEFAULT_TEMPLATE } from '../constants/constants';
import _ from 'lodash';
import { EventUserLikeState, USER_ID_PREFIX } from '../constants/constants';

export const getStreamGalleryImageUrl = (stream, templates) => {

	const primaryPhotoId = stream.primaryPhotoId;
	var imageUrl = DEFAULT_TEMPLATE;

	if ((primaryPhotoId === undefined) || (primaryPhotoId === null) || (stream.assets === undefined)) {

		imageUrl = getStreamTemplateImageUrl(stream, templates)

		return imageUrl;
	}

	const asset = stream.assets[stream.primaryPhotoId];

	if (asset === undefined) {
		return imageUrl;
	}

	return asset.url;
}

export const getStreamTemplateImageUrl = (stream, templates) => {
	// const primaryPhotoId = stream.primaryPhotoId;
	var imageUrl = DEFAULT_TEMPLATE;

	if (templates) {
		_.forIn(templates, (value, key) => {
			//console.log(value.data());
			if (stream.templateID === key) {
				imageUrl = value.imageUrl;
			}
		});
	}

	return imageUrl;
}

export const doesUserLikeAsset = (asset, authUser) => {

	if (asset) {
		if (asset.likes && asset.likes[USER_ID_PREFIX + authUser.uid] !== undefined) {
			const like = asset.likes[USER_ID_PREFIX + authUser.uid];
	
			if (like.state === EventUserLikeState.Liked)
				return true;
		}
	}
	

	return false;
}

