import firebase from 'firebase/compat/app'
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "firebase/compat/auth";


const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};


// Initialize Firebase
firebase.initializeApp(config);

export const firestoreDB = firebase.firestore();
export const firebaseAuth = firebase.auth();

export const firebaseStorage = firebase.storage();
export const storageRef = firebaseStorage.ref();