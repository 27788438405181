import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux'

class AddCommentForm extends React.Component {

	renderInput = ({ input, type, placeholder, label, meta }) => {
		const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
		return (
			<div className={className} >
				{/* <input {...input} autoComplete="off" type={type} placeholder={label}/> */}

				<textarea className="kithCommentTextArea" rows="2" {...input} placeholder={placeholder} type={type} />
			</div>
		);
	}

	onSubmit = (formValues) => {
		const { asset } = this.props
		this.props.onSubmit({ assetId:asset.id, ...formValues});
	}


	render() {
		const { asset } = this.props
		return (
			<form className="ui inverted form error" onSubmit={this.props.handleSubmit(this.onSubmit)} style={{ padding: '10px' }}>
				<div className="ui medium kithModalTitle">Add Comment</div>
				<div style={{ textAlign: 'center', marginBottom: '10px' }} >
					<img
						style={{ height: '80px', width: '200px', objectFit: 'scale-down', position: 'relative' }}
						alt=""
						src={asset?.url}
						onError={(e) => {
							if (!asset.isVideo) {
								e.target.onerror = null;
								e.target.src = asset.url
							}
						}}
					/>
				</div>
				<Field name="comment" component={this.renderInput} label="add comment" placeholder="Please add a comment" type="text" asset={this.props.asset} />
				<button className="kithButton kithModalPositive">Submit</button>
				<button className="ui button kithButtonSecondary" onClick={this.props.onDismiss} >Cancel</button>
			</form>
		);
	}
}

const validate = (formValues) => {

	const errors = {};

	if (!formValues.comment) {
		errors.password = 'Comment must not be empty';
	}


	return errors;
}

const mapStateToProps = (state, props) => ({
	initialValues: {
		assetId: props.assetId,
		asset: props.asset,
		comment: null
	}
})

export default connect(mapStateToProps)
	(reduxForm({
		form: 'addCommentForm',
		enableReinitialize: true,
		validate: validate
	})
		(AddCommentForm));