import React from 'react';
import Terms from '../components/Terms';
import background from '../assets/background.jpg';

class TermsContainer extends React.Component {

  render() {
    return (
    	<div style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'repeat', height: '100%' }}>
      		<Terms />
      	</div>	
    )
  }
}

export default TermsContainer;