import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../constants/routes';


const Footer = () => {

  return (
    <div className="kithFooter"> 
		Copyright © 2023 &nbsp;&nbsp;<a target="_blank" rel="noopener noreferrer" href="http://www.faredgestudio.com">Far Edge Studio, LLC</a><br></br>
		<Link to={ROUTES.PRIVACY} >Privacy</Link>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		<Link to={ROUTES.TERMS} >Terms</Link>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		<a href="mailto:info@faredgestudio.com?subject=About Loop PHOTOSTREAM">Contact Us</a>
    </div>
  )
}

export default Footer;