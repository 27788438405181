import React from 'react';

import appstore from '../assets/appstore.png';
import playstore from '../assets/playstore.png';

const NoContent = () => {

  return (
    <div className="kithContainer">
        <div className="ui centered grid">
          <div className="row">
            <img src="images/Logo.svg" alt="No Content Logo" className="kithNoContentLogo" />
          </div>
          <div className="row">
            <p style={{ marginLeft:'40px', marginRight:'40px'}}>You haven&apos;t created any streams yet.<br/><br/>Head to the app to upload pics and start inviting friends!</p>
          </div>
          <div className="row" >
              <div className="column" style={{ width:'200px'}} onClick={() =>  window.open("https://apps.apple.com/us/app/kith-photostream/id1339817111", "_blank")}>
                <img  className="kithMobileStoreImage" src={ appstore } alt="Loop Mobile Store iOS"  />
              </div>
              <div className="column" style={{ width:'200px'}} onClick={() =>  window.open("https://play.google.com/store/apps/details?id=com.faredge.eventphotos&hl=en_us", "_blank")}>
                <img  className="kithMobileStoreImage" src={ playstore } alt="Loop Mobile Store Android"  />
              </div>
          </div>
        </div>
    </div>
    
  );
}

export default NoContent;