import React, { Fragment, useEffect, useState } from 'react';
import { androidRenewMessage, appleRenewMessage, kPurchaseAnnualSubscriptionProductId, kPurchaseMonthlySubscriptionProductId } from '../constants/constants';
import { renderDate } from '../helpers/renderHelper';
import accountManagerInstance from '../managers/AccountManager';


const Payment = ({auth}) => {

	const [activeSub, setActiveSub] = useState()

	useEffect(()=>{
		accountManagerInstance.setAuth(auth)
		//console.log('auth = ', auth)

		setActiveSub(accountManagerInstance.activeSubscription())
		
	},[auth])

	//console.log('activeSub = ', activeSub)

	function renderPurchases() {
		if (activeSub) {
			const renewDate = renderDate(activeSub.expiresDateMs)

			var message = ""
			if (activeSub.platform === 'ios') {
				message = appleRenewMessage
			} else if (activeSub.platform === 'android') {
				message = androidRenewMessage
			}

			if (activeSub.productId === kPurchaseMonthlySubscriptionProductId) {
				return (
					<Fragment>
						<div style={{alignItems:'center', display:'flex'}}>
							<div className='kithButton' style={{width:100, alignSelf:'center', position:'relative', margin:'auto'}}>Monthly</div>
						</div>
						<div style={{marginTop:10}}>{`Renews:  ${renewDate}`}</div>
						<div style={{marginTop:10, marginLeft:40, marginRight:40}}><p>{ message }</p></div>
					</Fragment>
				)
			} else if (activeSub.productId === kPurchaseAnnualSubscriptionProductId) {
				return (
					<Fragment>
						<div style={{alignItems:'center', display:'flex'}}>
							<div className='kithButton' style={{width:100, alignSelf:'center', position:'relative', margin:'auto'}}>Annually</div>
						</div>
						<div style={{marginTop:10}}>{`Renews:  ${renewDate}`}</div>
						<div style={{marginTop:10, marginLeft:40, marginRight:40}}><p>{ message }</p></div>
					</Fragment>
				)
			} else {
				renderNoSubs()
			}
		} else {
			return renderNoSubs()
		}
	}

	function renderNoSubs() {
		return (
			<Fragment>
				<div style={{marginTop:50}}>
					<div>You have no active subscriptions</div>
					<div>Subscribe in the app now to unlock unlimited photo and video uploads to all of your streams</div>
				</div>
			</Fragment>
			
		)
	}

	return (
		<div className='kithProfileContainer' >
			<div className="kithProfileGrid" style={{width:'70vw', height: '23vh'}}>
				{ auth?.isPullingPurchases === true ? 
					<div className="ui medium text padded active loader" style={{ marginTop:40}}>Loading</div> 
					:
					renderPurchases()
				} 
			</div>
		</div>
	)
}

export default Payment