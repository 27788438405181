import axios from "axios"

export function generateDynamicLink(eventId, assetId, shotenedLink ) {


	const linkString = `https://loopphotostream.com/?event=${eventId}&asset=${assetId}`


	const payload = {"dynamicLinkInfo": {
		"domainUriPrefix": `${process.env.REACT_APP_DYNAMIC_LINK_DOMAIN}`,
		"link": linkString,
		"androidInfo": {
			"androidPackageName": "com.faredge.eventphotos",
			"androidMinPackageVersionCode":"1"
		},
		"iosInfo": {
			"iosBundleId": "com.faredge.eventphotos",
			"iosAppStoreId":"1339817111",
			"iosCustomScheme":"loop.faredgestudio.com"
		}

	}}


	axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_API_KEY}`,
		JSON.stringify(payload),
		{
			headers:{'Content-Type':'application/json'}
		}
	).then( response => {
		console.log('Dynamic Link response = ', response);

		var shortlink = null
		if (response.data) {
			shortlink = response.data['shortLink']
		}

		shotenedLink && shotenedLink(shortlink)


	})
	.catch(error => {
		//console.log('Dynamic Link error = ', error);
		shotenedLink && shotenedLink(linkString)
	})


}