import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { signInWithEmailAndPassword } from '../../actions/authActions';
import { renderLoader } from '../../helpers/renderHelper';
import { isEmpty } from 'lodash';


const renderInput = ({ input, label, type, meta: { touched, error, warning }, placeholder }) => {

	const className = `field ${error && touched ? 'error' : ''}`;
	return (
		<div className={className} >
			<label htmlFor="email" style={{color:'white'}}>{label}</label>
			<input {...input} autoComplete="off" type={type} placeholder={placeholder} />
			{touched && ((error && <span style={{color:'lightGray'}}>{error}</span>) || (warning && <span style={{color:'white'}}>{warning}</span>))}
		
		</div>
	);
}

var LoginForm = props => {
	
	const [isLoading, setLoading] = useState(false)
	const dispatch = useDispatch()

	const authError = useSelector(state => state.auth.authError)

	useEffect(()=> {
		if (!isEmpty(authError)) {
			setLoading(false)
		}
	},[authError])

	const onSubmit = (formValues) => {

		setLoading(true)
		dispatch(signInWithEmailAndPassword(formValues.email,formValues.password))
	}

	const onForgotPassword = e => {
		e.stopPropagation()
	}

	return (
		<div style={{margin:'auto', width:`50%`}}>
			{  isLoading &&  renderLoader()}
			<div className="ui medium kithModalTitle">Login</div>
			<form className="ui form" onSubmit={props.handleSubmit(onSubmit)} style={{padding:20}} >

				<Field name="email" component={renderInput} placeholder="Email" type="email" />
				<Field name="password" component={renderInput} placeholder="Password" type="password" />	

				<button type="submit" className="kithButton kithModalPositive" >Submit</button>
				<button className="ui button kithButtonSecondary" onClick={ onForgotPassword }>Forgot Password</button>

			</form>
		</div>
	);
}

const validate = (formValues) => {

	const errors = {};

	if (!formValues.email) {
		errors.email = 'You must enter an email';
	}

	if (!formValues.password) {
		errors.password = 'You must enter a password';
	}

	return errors;
}

export default reduxForm({ form: 'loginForm', validate: validate})(LoginForm);