import React from 'react';
import Contact from '../components/Contact';
import background from '../assets/background.jpg';

class ContactContainer extends React.Component {

  render () {
    return (
    	<div style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'repeat', height: '100vh' }}>
    		<Contact />
    	</div>	
    );
  }
}

export default ContactContainer;