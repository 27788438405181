import firebase from 'firebase/compat/app'
import history from '../history';
import 'firebase/storage';

import {
	SIGN_IN,
	SIGN_OUT,
	CREATE_USER,
	DELETE_USER,
	KITH_USER,
	AUTH_ERROR,
	FORGOT_PASSWORD,
	GET_USER_PURCHASES,
	PULLING_PURCHASES
} from './authActionTypes';
import { USER_ID_PREFIX } from '../constants/constants';
import { firebaseAuth } from '../firebase/firebase';

// export const signIn = (formValues) => async (dispatch, getState) => {

// 	const { email, password } = formValues;

// 	// Need to call Firebase login
// 	firebaseAuth.signInWithEmailAndPassword(email, password).then(function () {

// 		console.log(firebaseAuth.currentUser);

// 		dispatch({ type: SIGN_IN, payload: firebaseAuth.currentUser })

// 		history.push('/streams');

// 	}).catch(function (error) {
// 		dispatch({ type: AUTH_ERROR, payload: error });
// 		//console.log(error)
// 	});
// }

export const signInWithEmailAndPassword = (email, password) => async (dispatch, getState) => {

	// Need to call Firebase login
	firebaseAuth.signInWithEmailAndPassword(email, password).then(function () {

		console.log(firebaseAuth.currentUser);

		dispatch({ type: SIGN_IN, payload: firebaseAuth.currentUser })

		//history.push('/streams');
		window.location.reload()

	}).catch(function (error) {
		dispatch({ type: AUTH_ERROR, payload: error });
		//console.log(error)
	});
}

export const forgotPassword = (email) => async (dispatch, getState) => {

	// Need to call Firebase login
	firebaseAuth.sendPasswordResetEmail(email).then(function () {

		dispatch({ type: FORGOT_PASSWORD, payload: true })

	}).catch(function (error) {
		dispatch({ type: AUTH_ERROR, payload: error });
		//console.log(error)
	});
}

export const signOut = () => async (dispatch, getState) => {

	// Need to call Firebase logout
	firebaseAuth.signOut().then(function () {
		// Sign-out successful.
		dispatch({ type: SIGN_OUT })

		history.push('/')
		window.location.reload()
	}).catch(function (error) {
		// An error happened.
		console.log(error)
	});
}

export const createUser = (formValues) => async (dispatch, getState) => {

	dispatch({ type: CREATE_USER })

	history.push('/')
	window.location.reload()
}

export const deleteUser = () => async (dispatch, getState) => {

	dispatch({ type: DELETE_USER })

	history.push('/')
	window.location.reload()
}

export const updateUser = (formValues) => async (dispatch, getState) => {
	var user = firebaseAuth.currentUser;
	var userUpdate = {}
	if (formValues.fullName !== '' && formValues.fullName !== undefined && formValues.fullName !== null) {
		userUpdate = { ...userUpdate, fullName: formValues.fullName }
	}

	if (formValues.userName !== '' && formValues.userName !== undefined && formValues.userName !== null) {
		userUpdate = { ...userUpdate, userName: formValues.userName }
	}

	// if(formValues.email !== '' && formValues.email !== undefined && formValues.email !== null){
	//   user.updateEmail(formValues.email).then(() => {
	//     dispatch({type: UPDATE_USER})
	//   }).catch(function(error) {
	//     dispatch({type: AUTH_ERROR, payload:error});
	//   });
	// }

	const db = firebase.firestore();
	db.collection('users').doc(user.uid).set(
		userUpdate
		, { merge: true }
	).then(ref => {
		dispatch(fetchKithUser(user.uid));
	}).catch(function (error) {
		// An error happened.
		//console.log(error);
		dispatch({ type: AUTH_ERROR, payload: error });
	});
}

export const updateUserPassword = (formValues) => async (dispatch, getState) => {
	const { oldpassword, newpassword, confirmnewpassword } = formValues

	if (confirmnewpassword !== newpassword) {
		dispatch({ type: AUTH_ERROR, payload: { message: "New password does not match" } });
	} else {
		var user = firebaseAuth.currentUser;
		firebaseAuth.signInWithEmailAndPassword(user.email, oldpassword).then(function () {

			//console.log(firebaseAuth.currentUser);

			user.updatePassword(newpassword).then(function () {
				dispatch({ type: SIGN_IN, payload: firebaseAuth.currentUser });
			}).catch(function (error) {
				// An error happened.
				dispatch({ type: AUTH_ERROR, payload: error });
			});

		}).catch(function (error) {
			// An error happened.
			//console.log(error);
			dispatch({ type: AUTH_ERROR, payload: error });
		});
	}
}

export const fetchAuthUser = () => dispatch => {
	firebaseAuth.onAuthStateChanged(user => {
		if (user) {
			dispatch({ type: SIGN_IN, payload: firebaseAuth.currentUser });
		} else {
			dispatch({ type: SIGN_OUT })
		}
	});
};


export const fetchKithUser = (userID) => async (dispatch, getState) => {

	const db = firebase.firestore();
	let query = db.collection('users').doc(userID);

	await query.get().then(snapshot => {
		if (snapshot.empty) {
			console.log('No matching user documents.');
			return;
		}
		dispatch({ type: KITH_USER, payload: snapshot.data() });
	});
};


export const clearAuthError = () => async (dispatch) => {
	dispatch({ type: AUTH_ERROR, payload: null });
};

export const clearPasswordSentSuccess = () => async (dispatch) => {
	dispatch({ type: FORGOT_PASSWORD, payload: false });
};

export const updateAvatar = (file) => async (dispatch, getState) => {
	const user = firebaseAuth.currentUser;
	const db = firebase.firestore();

	var storageRef = firebase.storage().ref();
	var avatarRef = storageRef.child("avatars/" + user.uid + "/avatar_image.jpg");

	avatarRef.put(file).then(snapshot => {
		snapshot.ref.getDownloadURL().then(downloadURL => {
			db.collection('users').doc(user.uid).set(
				{ avatarURL: downloadURL }
				, { merge: true }
			).then(ref => {
				dispatch(fetchKithUser(user.uid));
			}).catch(function (error) {
				dispatch({ type: AUTH_ERROR, payload: error });
			});
		});
	})
};

export const fetchUserPurchases = () => async(dispatch, state) => {
	const { authUser } = state().auth
	const db = firebase.firestore();
	const user_id = authUser.uid

	const query = db.collection('users').doc(user_id).collection('purchases')

	dispatch({type:PULLING_PURCHASES, payload: true})

	query.get().then(snapshot => {

		if (snapshot.empty) {
			//console.log('No matching Comments documents.');
			dispatch({type:PULLING_PURCHASES, payload: false})
			return;
		}

		dispatch({ type: GET_USER_PURCHASES, payload: snapshot})

	}).catch(err => {
		console.log('Error getting documents', err);
		dispatch({type:PULLING_PURCHASES, payload: false})
		dispatch({ type: AUTH_ERROR, payload: err });
	});
}

