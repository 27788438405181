import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import streamsReducer from './streamsReducer';
import templatesReducer from './templatesReducer';
import userReducer from './userReducer';

import { SIGN_OUT } from '../actions/authActionTypes';

const appReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  streams: streamsReducer,
  user: userReducer,
  templates: templatesReducer
});

const rootReducer = (state, action) => {
  //intercept and clear all state if sign out is called
  if (action.type === SIGN_OUT ) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;