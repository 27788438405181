import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import AddCommentForm from '../forms/AddCommentForm';

const AddCommentModal = ({ onDismiss, onSubmit, asset, assetId}) => {

	useEffect(()=>{
		window.scrollTo({top: 0 })
		document.body.style.overflow = 'hidden';

		return() => {
			document.body.style.overflow = 'unset';
		}

	},[])

	return ReactDOM.createPortal(
		<div className="ui container">
			<div className="ui dimmer modals visible active kithModalOuter" onClick={onDismiss} >
				<div onClick={(e) => e.stopPropagation()} className="ui inverted modal visible active kithModalInner" >
				<AddCommentForm onSubmit={ onSubmit } onDismiss={ onDismiss } asset={asset} assetId={assetId}/>
				</div>
			</div>
		</div>
		,
		document.querySelector('#modal')
	);	
}

export default AddCommentModal;