import _ from 'lodash'
import { firestoreDB } from '../firebase/firebase';

import { 
  TEMPLATES
} from './streamActionTypes';

export const fetchTemplates = () => _.memoize(async(dispatch, getState) => {

  //const { authUser } = getState().auth

  let query = firestoreDB.collection('templates');

  query.get().then(snapshot => {

      if (snapshot.empty) {
        console.log('No templates.');
      }  

      dispatch( { type: TEMPLATES, payload: snapshot})

  }).catch(err => {
      console.log('Error getting documents', err);
  });
});