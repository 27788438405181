import { isEmpty, filter, last } from "lodash";
import { kPurchaseAnnualSubscriptionProductId, kPurchaseMonthlySubscriptionProductId } from "../constants/constants";
var currentUser
var auth
class AccountManager {

	
	constructor() {
	}

	setCurrentUser(propsCurrentUser) {
		currentUser = propsCurrentUser
	}

	setAuth(_auth) {
		auth = _auth;
	}

	getCurrentUser() {
		console.log('currentUser = ', currentUser)
	}

	hasActiveSubscription() {
		const subs = this.activeSubscription()
		// console.log('this.activeSubscription() = ', subs)
		return subs !== undefined
	}

	activeSubscription() {
		if (auth) {
			if (!isEmpty(auth.purchases)) {
				const subscriptionPurchases = filter(auth.purchases, purchase => { 
					if(purchase.verified === true && 
						(purchase.productId === kPurchaseMonthlySubscriptionProductId ||
						purchase.productId === kPurchaseAnnualSubscriptionProductId )){
								return purchase
					} 
				})
	
				// check for expiration
				if (!isEmpty(subscriptionPurchases)) {
					const activeSubscriptions = filter(subscriptionPurchases, purchase => {
						if (purchase.expiresDateMs) {
							// console.log('purchase.expiresDateMs = ',purchase.expiresDateMs)
							// console.log('today = ', new Date().getTime() )
							if (purchase.expiresDateMs > new Date().getTime()) {
								return purchase
							}
						}
						
					} )
	
					if (!isEmpty(activeSubscriptions)) {
						return last(activeSubscriptions)
					}
				}	
			}
		}
	}
}

const accountManagerInstance = Object.freeze(new AccountManager())

export default accountManagerInstance