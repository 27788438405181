import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux'

class EditAccountForm extends React.Component {
	renderError({error, touched}) {
		if (touched && error) {
		  return (
		    <div className="ui error message">
		      <div className="header">{error}</div>
		    </div>
		  )
		}

		return null
	}

	renderInput = ({input, type, label, meta}) => {
		const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
		return (
		  <div className={className} >
		    <label>{label}</label>
		    <input {...input} autoComplete="off" type={type} placeholder={label} />
		    <div>{this.renderError(meta)}</div>
		  </div>
		);
	}

	onSubmit = (formValues) => {
    	this.props.onSubmit(formValues);
  	}

	render() {
		return (
	      <form className="ui inverted form error" onSubmit={this.props.handleSubmit(this.onSubmit)} style={{ padding: '20px' }}>
					<div className="ui medium kithModalTitle">Update Account</div>
	        <Field name="fullName" component={this.renderInput} label="Full Name" type="text" />
	        <Field name="userName" component={this.renderInput} label="Display Name" type="text" />
					<button className="kithButton kithModalPositive" >Submit</button>
					<button className="ui button kithButtonSecondary" onClick={ this.props.onDismiss }>Cancel</button>
	      </form>
	    );
	}	
}	

const validate = (formValues) => {

  const errors = {};

  if (!formValues.fullname) {
    errors.password = 'You must enter your fullname';
  }

  if (!formValues.email) {
    errors.password = 'You must enter an email address';
  }

  if (!formValues.displayname) {
    errors.password = 'You must enter your display name';
  }

  return errors;
}	

const mapStateToProps = (state, props) => ({
  initialValues: {
  	fullName: state.auth.currentUser.fullName, 
  	userName: state.auth.currentUser.userName
  }	
})

export default connect(mapStateToProps)
(reduxForm({
  form: 'editAccountForm',
  enableReinitialize : true,
  validate: validate })
(EditAccountForm));