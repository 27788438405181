import React from 'react';

import background from '../assets/background.jpg';
import Image404 from '../assets/404.svg';

class PageNotFound extends React.Component {

	render() {
		return (
			<div className='kithContainer' style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'repeat', height: '100vh', color: 'white'}}>
				<img src={ Image404 } alt="404" style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', paddingTop: '50px', width: '20%' }} />
				<div style={{textAlign:'center'}}>
					<h2>Page Not Found</h2>
					<div>The page you are looking for doesn&apos;t exist or has been moved.</div>
					<button className="ui button kithButtonSecondary" 
						onClick={ (e)=> {
							e.preventDefault();
							window.location.href='/';
						}} 
						style={{marginTop:'20px'}} >Home</button>
				</div>
		</div>	
		)
	}
}	

export default PageNotFound;