import React from 'react';

export function renderLoader() {
	return (
		<div className="ui active dimmer">
			<div className="ui medium text padded loader">Loading</div>
		</div>	
	)
}

const options = { year: "numeric", month:"short", day:"numeric" };
export function renderDate(dateInMS) {
	if (dateInMS) {
		const formattedDate = new Date(dateInMS)
		return formattedDate.toLocaleDateString("en-US", options)
	}
	
}