import { firestoreDB } from '../firebase/firebase';

export const USER = "USER"

export const fetchUser = (userID) => async(dispatch, getState ) => {

    const query = firestoreDB.collection('users').doc(userID);
	// console.log('fetchUser');

    await query.get().then(snapshot => {
		if (snapshot.empty) {
			console.log('No matching user documents.');
			return;
		}  
	//   console.log('snapshot.data() = ', snapshot.data());
		dispatch({type: USER, payload: snapshot.data()});
    });
  };