import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchUser } from '../actions/userActions';
import { renderDate } from '../helpers/renderHelper';


const CommentCard = (props) => {
	const comment =  props.comment
	const poster = useSelector(state => state?.user?.user) 

	const createdDate = new Date(comment?.createdAt)
	const postedTime = renderDate(comment?.createdAt) + " at " + createdDate?.toLocaleTimeString()
	
	useEffect(()=>{
		if (comment) {
			const creatorID = comment.createdBy.substring(8, comment.createdBy.length) // Remove userId prefix
			fetchUser(creatorID)
		}
	},[])

	return(
		<div className="ui card" style={{ padding: '10px', background: '#262626', textAlign:'left' }} >
			<div className="ui text">
			<p>{comment?.comment}</p>
			</div>
			<br/>
			<div style={{textAlign:'right', fontSize:'11px', color:'darkgray'}}>
				By {poster?.userName} { postedTime && ' on ' + postedTime}
			</div>
		</div>
	)
}

export default CommentCard
