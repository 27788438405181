import { map } from 'lodash';

import {
	SIGN_IN,
	CREATE_USER,
	DELETE_USER,
	KITH_USER,
	AUTH_ERROR,
	FORGOT_PASSWORD,
	GET_USER_PURCHASES,
	PULLING_PURCHASES
} from '../actions/authActionTypes';

const INITIAL_STATE = {
	isSignedIn: null,
	authUser: null,
	authError: null,
	forgotPasswordSent: null,
	isPullingPurchases: false
}

export default (state = INITIAL_STATE, action) => {

	switch (action.type) {

		case SIGN_IN:
			console.log('SIGN_IN')
			return { ...state, isSignedIn: true, authUser: action.payload };

		case CREATE_USER:
			return { ...state, isSignedIn: true, authUser: action.payload };

		case DELETE_USER:
			return { ...state, isSignedIn: false, authUser: null };

		// case UPDATE_USER:
		//   return {...state, currentUser: action.payload };

		case PULLING_PURCHASES:
			return { ...state, isPullingPurchases: action.payload };

		case KITH_USER:
			return { ...state, currentUser: action.payload };

		case AUTH_ERROR:
			return { ...state, authError: action.payload };

		case GET_USER_PURCHASES:
			const purchases = map(action.payload.docs, doc => doc.data())
			return { ...state, purchases: purchases, isPullingPurchases: false };

		case FORGOT_PASSWORD:
			return { ...state, forgotPasswordSent: action.payload };

		default:
			return state;
	}
}