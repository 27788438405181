import React from 'react';
import Privacy from '../components/Privacy';
import background from '../assets/background.jpg';

class PrivacyContainer extends React.Component {

  render() {
    return (
    	<div style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'repeat', height: '100%' }}>
      		<Privacy />
      	</div>	
    )
  }
}

export default PrivacyContainer;