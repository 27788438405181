export const HOME = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const LANDING = '/landing';
export const STREAMS = '/streams/';
export const STREAMDETAIL = '/streams/:id';
export const FIRETV = '/firetv';
export const CONTACT = '/contact';
export const MOBILEAPPS = '/mobile-apps';
export const STREAMASSETDETAIL = '/streams/:id/assets/:assetId';
export const PRIVACY = '/privacy';
export const TERMS = '/terms';
