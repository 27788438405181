import React from 'react';
import { DEFAULT_TEMPLATE } from '../constants/constants';
import _ from 'lodash';

import '../css/StreamList.css'
import StreamGalleryCard from './StreamGalleryCard';
import { getStreamGalleryImageUrl, getStreamTemplateImageUrl } from '../helpers/streams';

const StreamList = ({streams, templates, onStreamClicked}) => {

  var imageUrl = DEFAULT_TEMPLATE;
  var fallbackUrl = DEFAULT_TEMPLATE;

  const eventStreams = _.map(streams, (streamValue, streamKey) => {

    if(streamValue.customUrl !== undefined ) {
      imageUrl = streamValue.customUrl
      fallbackUrl = streamValue.customUrlFallback
    } else {
      imageUrl = getStreamGalleryImageUrl(streamValue, templates);
      fallbackUrl = getStreamTemplateImageUrl(streamValue, templates);
    }
    
    return <StreamGalleryCard key={streamKey} stream={streamValue} streamId={streamKey} imageUrl={imageUrl} fallbackUrl={fallbackUrl} onStreamClicked={onStreamClicked}/>
  });

  return (
    <div className="ui container" style={{ paddingTop: 30 }}>
      <div className="ui three stackable cards" >
        {eventStreams}
      </div>  
    </div>
  );
}

export default StreamList;
