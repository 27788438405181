import React from 'react';

import Account from '../components/Account';
import Modal from '../components/modals/Modal';

import { connect } from 'react-redux';
import { signOut, fetchKithUser, updateUser, updateUserPassword, clearAuthError, updateAvatar } from '../actions/authActions';
import Payment from '../components/Payment';


class AccountContainer extends React.Component {

  constructor() {
    super()
    this.inputOpenFileRef = React.createRef()

    this.state = {
      editMode: false,
      showChangePassword: false,
      error: null
    }
  }

  showOpenFileDlg = () => {
    this.inputOpenFileRef.current.click()
  }

  onChangeFile = (e) => {
    var file = e.target.files[0];
    if(file !== undefined && file !== null){
      this.props.updateAvatar(file);
    }
  }

  onSignOutClicked = () => {
    this.props.signOut();
  }

  onEditAccountClicked = () => {
    this.setState({
      editMode: true,
      showChangePassword: this.state.showChangePassword,
      error: null
    });
  }

  onChangePasswordClicked = () => {
    //show change password modal
    this.setState({
      editMode: this.state.editMode,
      showChangePassword: !this.state.showChangePassword,
      error: null
    });
  }

  onCancelClicked = () => {
    this.setState({
      editMode: false
    });
  }

  onChangePasswordSubmit = (formValues) => {
    this.props.updateUserPassword(formValues)
    this.onDismiss()
  }

  onEditAccountSubmit = (formValues) => {
    this.props.updateUser(formValues)
    this.onCancelClicked()
  }

  onDismiss = () => {
    const { authError } = this.props.auth;
    this.setState({
      editMode: this.state.editMode,
      showChangePassword: false,
      error: authError
    });
  }

  onDismissError = () => {
    const { authError } = this.props.auth;
    this.props.clearAuthError();

    this.setState({
		editMode: this.state.editMode,
		showChangePassword: this.state.showChangePassword,
		error: authError
    });
  }

  renderError = () => {
	const { authError } = this.props.auth;

	if(authError !== null){

		//console.log('we have error = ', authError.message )
		var params = {
		title:'Error',
		content: authError.message,
		onDismiss: 
			//We dismiss by setting the state with the current error which will re-render
			this.onDismissError
		}

		return(
		<Modal {...params} />
		);
	}
  }

  componentDidUpdate() {

    const { authUser, currentUser } = this.props.auth;
    if(authUser !== undefined && currentUser === undefined && authUser !== null ){
      	this.props.fetchKithUser(authUser.uid)
    }
  }  

  render() {
    return (
      <div className="kithContainer" style={{ textAlign:'center' }}>
        <Account 
          auth={ this.props.auth } 
          showChangePassword={ this.state.showChangePassword }
          onSignOutClicked={ this.onSignOutClicked } 
          onEditAccountClicked={ this.onEditAccountClicked }  
          editMode={ this.state.editMode }
          onEditAccountSubmit={ this.onEditAccountSubmit }
          onCancelClicked={ this.onCancelClicked }
          onChangePasswordClicked={ this.onChangePasswordClicked }
          onChangePasswordSubmit={ this.onChangePasswordSubmit }
          onDismiss={ this.onDismiss }
          showOpenFileDlg={ this.showOpenFileDlg }
        />
		<Payment auth={ this.props.auth }/>
        <div>
        	{this.renderError()}
        </div>
        <div>
        	<input ref={this.inputOpenFileRef} type="file" accept="image/png, image/jpeg" style={{display:"none"}} onChange={this.onChangeFile} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {auth: state.auth }
}

export default connect(mapStateToProps, { signOut, fetchKithUser, updateUser, updateUserPassword, clearAuthError, updateAvatar })(AccountContainer);