import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StreamList from '../components/StreamList';
import { fetchMyStreams, updateMyStreams } from '../actions/streamActions'
import { fetchTemplates } from '../actions/templatesActions';
import { USER_ID_PREFIX } from '../constants/constants';
// import LoadingOverlay from 'react-loading-overlay';

import _ from 'lodash';

import '../css/StreamList.css'

import { EventState } from '../constants/constants';
import { useNavigate } from 'react-router-dom';
import { renderLoader } from '../helpers/renderHelper';
import { firestoreDB } from '../firebase/firebase';


const StreamListContainer = () => {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const streams = useSelector(state => state.streams)
	const templates = useSelector(state => state.templates)
	const authUser = useSelector(state => state.auth.authUser)

	var myStreamsListener

	useEffect(()=> {

		if (_.isEmpty(templates)) {
			dispatch(fetchTemplates())
		}

		registerFirebaseListeners()

		dispatch(fetchMyStreams() )

		return() =>{
			myStreamsListener && myStreamsListener()
		}
	},[authUser])


	function registerFirebaseListeners(){

		if (authUser) {

			myStreamsListener = firestoreDB.collection('events').where(('members.' + USER_ID_PREFIX + authUser.uid), '==', true).where('state', '==', EventState.Active).onSnapshot(snapshot => {
				//this.props.updateMyStreams(snapshot);
			}, err => {
				console.log(err);
			});
		}
		
	}

	const onStreamClicked = (streamId, stream) => {
		navigate(`/streams/${streamId}`)
	}

	function renderContent() {

		if (streams != null) {
			if (!_.isEmpty(streams)) {
				return (
					<StreamList streams={streams} templates={templates} onStreamClicked={ onStreamClicked } />
				);
			} else {
				return renderLoader()
			}
		} else {
			return <div />
		}
	}

	return (
		<div className='kithContainer'>
			
			{ streams === null ? renderLoader() : renderContent() }
		</div>
	)
}

export default StreamListContainer

// class StreamListContainer extends React.Component {

// 	componentDidMount() {

// 		// Only request this if the templates is empty
// 		if (_.isEmpty(this.props.templates)) {
// 			this.props.fetchTemplates();
// 		}

// 		// this.registerFirebaseListeners();
// 	}

// 	componentDidUpdate() {

// 		const { authUser } = this.props;

// 		if ((this.myStreamsListener === undefined) && (authUser !== null)) {

// 			this.props.fetchMyStreams();

// 			this.registerFirebaseListeners();
// 		}
// 	}

// 	componentWillUnmount() {

// 		this.myStreamsListener && this.myStreamsListener();
// 	}

// 	registerFirebaseListeners = () => {

// 		const { authUser } = this.props;

// 		const db = firebase.firestore();

// 		this.myStreamsListener = db.collection('events').where(('members.' + USER_ID_PREFIX + authUser.uid), '==', true).where('state', '==', EventState.Active).onSnapshot(snapshot => {
// 			//this.props.updateMyStreams(snapshot);
// 		}, err => {
// 			console.log(err);
// 		});
// 	}

// 	onStreamClicked = (streamId, stream) => {
// 		history.push(`/streams/${streamId}`)
// 	}

// 	renderContent() {
// 		const { streams, templates } = this.props;

// 		if (streams != null) {
// 			if (!_.isEmpty(streams)) {
// 				return (
// 					<StreamList streams={streams} templates={templates} onStreamClicked={this.onStreamClicked} />
// 				);
// 			} else {
// 				return (
// 					<NoContentController />
// 				)
// 			}
// 		} else {
// 			return <div />
// 		}
// 	}

// 	render() {

// 		const { streams } = this.props;

// 		// return (
// 		//   <LoadingOverlay
// 		//   active={ streams == null }
// 		//   spinner
// 		//   text='Loading...'>

// 		//   { this.renderContent() }
// 		// </LoadingOverlay>
// 		// )

// 		return (
// 			<div>
// 				{this.renderContent()}
// 			</div>
// 		)
// 	}
// }

// const mapStateToProps = (state, ownProps) => {
// 	return {
// 		streams: state.streams,
// 		templates: state.templates,
// 		authUser: state.auth.authUser
// 	}
// }

// export default connect(mapStateToProps, { fetchMyStreams, fetchTemplates, updateMyStreams })(StreamListContainer);