import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgotPassword, signInWithEmailAndPassword } from '../actions/authActions';
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm';
import LoginForm from '../components/forms/LoginForm';
import * as ROUTES from '../constants/routes';


const HomeContainer = () => {

	const auth = useSelector(state => state.auth)
	const isSignedIn = useSelector(state => state.auth.isSignedIn)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(()=> {
		if (isSignedIn === true) {
			navigate(ROUTES.STREAMS)
		}
	},[isSignedIn])

	function onSubmit(e) {
		e.preventDefault()
		dispatch(signInWithEmailAndPassword(e.email, e.password))
	}

	function onForgotPassword(e) {
		e.preventDefault()
		dispatch(forgotPassword(e.email))
	}


	return (
		<div className="kithContainer">
			<div style={{textAlign:'center', color:'white', marginTop:40, fontSize: '30px', marginBottom: 20}}>Welcome to Loop PHOTOSTREAM</div>
				{ auth && !isSignedIn && <LoginForm onSubmit={ onSubmit } onDismiss={ ()=>{} } onForgotPassword={ onForgotPassword } /> }
				{/* <ForgotPasswordForm /> */}
		</div>
	)
}

export default HomeContainer