import { Field, reduxForm } from 'redux-form';
import React from 'react';

var ForgotPasswordForm = () => {

	function renderError({ error, touched }) {
		if (touched && error) {
			return (
				<div className="ui error message">
					<div className="header">{error}</div>
				</div>
			)
		}

		return null
	}

	const renderInput = ({ input, label, type, meta, placeholder }) => {

		const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
		return (
			<div className={className} >
				<label>{label}</label>
				<input {...input} autoComplete="off" type={type} placeholder={placeholder} />
				<div>{ renderError(meta) }</div>
			</div>
		);
	}

	return (
		<div style={{margin:'auto', width:`50%`}}>
				
				<form className="ui form" onSubmit={ () => {} } >
					<div className="ui medium kithModalTitle">Forgot Password</div>
					<Field name="email" component={ renderInput} placeholder="Email" type="email" />

					<button className="kithButton kithModalPositive" >Submit</button>
					
				</form>
		</div>
	)
}

const validate = (formValues) => {

	const errors = {};

	if (!formValues.email) {
		errors.email = 'You must enter an email';
	}

	if (!formValues.password) {
		errors.password = 'You must enter a password';
	}

	return errors;
}

// create new, "configured" function
const createReduxForm = reduxForm({ form: 'loginForm', validate: validate })
ForgotPasswordForm = createReduxForm(ForgotPasswordForm)

export default ForgotPasswordForm