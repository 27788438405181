import React, { useState, useEffect } from 'react';
import { EventAssetType } from '../constants/constants'
import playIcon from '../assets/play_icon.svg';
import { getCommentCount } from '../helpers';
// import { doesUserLikeAsset } from '../helpers/streams';

const StreamAssetCard = ({assetId, auth, asset, isAssetLiked, assetLikeCount, onAssetClicked, onAssetLikeButtonClicked, onAssetCommentButtonClicked}) => {

  const [isLiked, setLiked] = useState(isAssetLiked);

  useEffect(() => {
      setLiked(isAssetLiked);
  },[isAssetLiked]);  


  const [likeCount, setLikeCount] = useState(assetLikeCount);
  useEffect(() => {
      setLiked(assetLikeCount);
  },[assetLikeCount]);

  if(asset){
      //console.log(asset)
      let isVideo = asset.type === EventAssetType.VideoAssetType

      var url = asset.url
      if (asset.thumbnailUrl !== undefined && asset.thumbnailUrl !== null) {
        if (asset.thumbnailUrl.length > 1) {
          url = asset.thumbnailUrl
        }
      }

      
      var likeLabel = 'like'

      if(likeCount > 1) {
        likeLabel = 'likes'
      }

      let comments = getCommentCount(asset)
      var commentLabel = 'comment'
      if(comments > 1){
        commentLabel = 'comments'
      }

      //let isLiked = doesUserLikeAsset(asset, auth.authUser)

    return(
      <div className="card" style={{ height: '300px' }} >
        <div className="image" onClick={() => onAssetClicked(assetId, asset)}>

            { isVideo ? 
               <img className="kithAssetCell"
                style={{ height: '40px', width: '40px', position: 'absolute', overflow: 'hidden', top: '45%', left:'45%' }}
                alt="" 
                src={ playIcon }
                />
              :
              (<div />)
            }

           <img className="kithAssetCell"
            style={{ height: 200, objectFit: 'scale-down' }} // fill contain cover scale-down none
            alt=""
            key={ assetId }
            src={ url }
            onError={ (e)=>{
              if(!isVideo){
                e.target.onerror = null; 
                e.target.src=asset.url
              } 
            }}
            /> 
        </div>
        <div className="ui inverted content">
          <span className="right floated">
            { comments > 0 ?
              <i className="comment icon" />
              :
              <i className="comment outline icon" />
            }
            
            { comments } { commentLabel }
          </span>
          { isLiked ?
            <i className="heart like icon" onClick={() => { setLiked(false); setLikeCount(likeCount - 1); onAssetLikeButtonClicked(assetId, asset); }} />
            :
            <i className="heart outline like icon" onClick={() => { setLiked(true); setLikeCount(likeCount + 1); onAssetLikeButtonClicked(assetId, asset); }}/>
          }
          
          { likeCount } { likeLabel }
        </div>
        <div className="extra content">
          <div className="ui large inverted transparent left icon input" onClick={() => onAssetCommentButtonClicked(assetId, asset)} >
            <i className="comment alternate icon"></i>
            <div style={{ marginLeft:'30px'}}>add comment... </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="card" >
        <div className="ui segment">
          <div className="ui active dimmer">
            <div className="ui text loader">Loading</div>
          </div>
          <p></p>
        </div>
      </div>     
    )
  }
}

export default StreamAssetCard;