import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import chevronRight from '../assets/chevron-right.svg';
import chevronLeft from '../assets/chevron-left.svg';
import closeIcon from '../assets/close_icon.svg'
import { EventAssetType } from '../constants/constants'
import { getLikeCount, getCommentCount } from '../helpers';
import CommentCard from './CommentCard';

const loadedAsset = (asset) => {

	if(asset) {
		if (asset.type === EventAssetType.VideoAssetType) {
			return (
				<video key={asset.id} style={{ objectFit: 'contain', width: '80vh', maxHeight: '50vh', marginTop: '20%', marginBottom: '20%', marginLeft: '6.5%', marginRight: '6.5%', display: 'block', position: 'flex' }} controls autoPlay >
					<source src={asset.url} />
					Your browser does not support the video
				</video>
			);
		} else if (asset.type === EventAssetType.ImageAssetType) {
			return (
				<img style={{ objectFit: 'contain', width: '80vw', height: '90vh', margin: 'auto', display: 'block' }}
					alt=""
					src={asset.url}
				/>
			);
		} 
	}
	
	return <div>Unknown asset type</div>
}

const loadAssetComments = (asset) => {
	if (getCommentCount(asset) <= 0) {
		return <p style={{ textAlign: 'center', position: 'relative', marginTop: '100%' }} >Be the first to comment by click the comment below</p>
	}

	// console.log("rendering comments = ", asset.comments)

	const commentCards = _.map(asset.comments, (comment, key) => {
		return <CommentCard comment={comment} key={key} />
	})

	return commentCards
}

const StreamAssetDetail = ({
	streamId,
	stream,
	assetId,
	asset,
	isAssetLiked,
	isFirst,
	isLast,
	onAssetLikeButtonClicked,
	onRightArrowClicked,
	onLeftArrowClicked,
	onDismiss,
	postedBy,
	onAssetCommentButtonClicked }) => {

	const [isLiked, setLiked] = useState(isAssetLiked);

	useEffect(()=>{
		window.scrollTo({top: 0 })
		document.body.style.overflow = 'hidden';

		return() => {
			document.body.style.overflow = 'unset';
		}

	},[])

	useEffect(() => {
		setLiked(isAssetLiked);
	}, [isAssetLiked]);

	const [likeCount, setLikeCount] = useState(getLikeCount(asset));
	useEffect(() => {
		setLiked(likeCount);
	}, [likeCount]);

	const options = {
		year: "numeric",
		month: "long",
		day: "numeric"
	};

	const createdDate = new Date(asset?.createdAt)
	const postedTime = createdDate.toLocaleDateString("en-US", options) + " at " + createdDate.toLocaleTimeString()

	var likeLabel = 'like'

	if (likeCount > 1) {
		likeLabel = 'likes'
	}

	let commentCount = getCommentCount(asset)

	var commentMessage = ''
	if (commentCount > 0) {
		var commentLabel = 'comment'
		if (commentCount > 1) {
			commentLabel = 'comments'
		}

		commentMessage = ' ' + commentCount + ' ' + commentLabel
	}

	const mainRef = useRef(null);

	useEffect(() => {
		mainRef.current.focus();
	}, [mainRef]);

	const RIGHT_ARROW_KEY = 39
	const LEFT_ARROW_KEY = 37
	const ESCAPE_KEY = 27

	const BORDER_COLOR = '1px solid #4a4a4a'
	var poster = "Unknown"
	if (postedBy !== undefined) {
		poster = postedBy.userName
	}


	return ReactDOM.createPortal(
		<div
			className="container"
			ref={mainRef} tabIndex="-1"
			onKeyDown={e => {
				console.log(e.keyCode)
				if (e.keyCode === RIGHT_ARROW_KEY && !isLast) {
					onRightArrowClicked()
				} else if (e.keyCode === LEFT_ARROW_KEY && !isFirst) {
					onLeftArrowClicked()
				} else if (e.keyCode === ESCAPE_KEY) {
					onDismiss()
				}
			}} >

			<div onClick={onDismiss} className="ui dimmer modals visible active" style={{ overflow: 'hidden', position: 'fixed' }} >
				<div onClick={(e) => e.stopPropagation()} className="ui standard modal visible active" style={{ background: 'transparent', width: '90vw' }}>
					<div className="ui grid container" style={{ border: BORDER_COLOR }} >
						<div className="row" style={{ padding: '0px' }}>
							<div className="twelve wide column" style={{ background: 'black', padding: '10px', overflow: 'hidden', textAlign: 'center' }}>
								{loadedAsset(asset)}
								{!isFirst &&
									<img className="kithLeftFloatingButton"
										src={chevronLeft} alt=""
										onClick={() => onLeftArrowClicked()}
									/>
								}

								{!isLast &&
									<img className="kithRightFloatingButton"
										src={chevronRight} alt=""
										onClick={() => onRightArrowClicked()}
									/>
								}
							</div>
							<div className="center aligned four wide column" style={{ backgroundColor: '#4a4a4a', color: 'white', padding: '10px' }} >
								<div className="kithCommentBar">
									<div style={{ overflow: 'hidden', float: 'center' }} >
										{commentMessage !== '' ?
											<i className="comment icon"></i>
											:
											<div />
										}

										{commentMessage}
									</div>
									<div className="ui container" style={{ paddingTop: 20, height: '85vh', overflow: 'auto' }}>
										<div className="ui one stackable cards">
											{loadAssetComments(asset)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row" style={{ padding: '0px' }} >
							<div className="twelve wide column" style={{ backgroundColor: '#4a4a4a', color: 'white', padding: '10px', borderRight: '1px solid black' }} >
								<div style={{ position: 'absolute', overflow: 'hidden' }} >

									{isLiked ?
										<i className="heart like icon" onClick={() => { setLiked(false); setLikeCount(likeCount - 1); onAssetLikeButtonClicked(assetId, asset) }} />
										:
										<i className="heart outline like icon" onClick={() => { setLiked(true); setLikeCount(likeCount + 1); onAssetLikeButtonClicked(assetId, asset) }} />
									}

									{likeCount} {likeLabel}
								</div>

								<div style={{ float: 'right', overflow: 'hidden' }} >
									Posted by {poster} on {postedTime}
								</div>
							</div>
							<div className="center aligned four wide column" style={{ backgroundColor: '#4a4a4a', color: 'white', padding: '10px', borderLeft: '1px solid black', borderTop: '1px solid black' }} >
								<div className="ui large inverted transparent left icon input" onClick={() => onAssetCommentButtonClicked(assetId, asset)}>
									<i className="comment icon"></i>
									<div style={{ marginLeft: '30px' }}>add comment... </div>
								</div>
							</div>
						</div>
					</div>
					<img src={closeIcon} style={{ position: 'absolute', bottom: '100%', left: '101%' }} onClick={onDismiss} alt="Close Button" />
				</div>
			</div>
		</div>
		,
		document.querySelector('#modal')
	);
}

export default StreamAssetDetail;